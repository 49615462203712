import { inmunetApi } from '../../../api';

export const getRadiologia = async() => {

    try {

        const { data } = await inmunetApi.get(`/radiologia`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.radiologias;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const getPacienteRadiologia = async(id) => {

    try {

        const { data } = await inmunetApi.get(`/paciente/radiologia/${id}`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.radiologia;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const createPacienteRadiologia = async({ data: radiologia, id }) => {

    try {

        let formData = new FormData();
        Object.keys(radiologia).forEach(field => {
            if (field === 'archivos') {
                radiologia[field].forEach((file, index) => {
                    formData.append(`archivos[${index}]`, radiologia[field][index]);
                });
            } else {
                formData.append(field, radiologia[field]);
            }
        });

        const { data } = await inmunetApi.post(`/paciente/radiologia/${id}`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al crear radiología';

        return data.radiologia;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error('Error al crear radiología');
        }

    }

};