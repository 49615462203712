import React from 'react';
import { useParams } from 'react-router-dom';

import { usePacienteInmunoterapias } from './hooks/usePacienteInmunoterapias';

import { Error, Loader } from '../../components';

import styles from './PacienteInmunoterapias.module.css';

export const PacienteInmunoterapias = () => {

  let { id } = useParams();

  const { pacienteInmunoterapiasQuery: { isLoading, isFetching , isError, data: { actuales, historicas } } } = usePacienteInmunoterapias(id);

  return (
    <div className="row">
      <div className="col">
          {(isLoading)
            ? <Loader />
            : <>
              {(isError)
              ? <Error />
              : <>
                <div className={styles.panel + " " + styles.panelNoHeader}>
                  <div className="row">
                    <div className="col">
                      <div className={styles.tableHeader}>Inmunoterapia actual</div>
                      <div className="table-responsive">
                        <table className="table align-middle">
                          <thead>
                            <tr>
                              <th scope="col">ITE</th>
                              <th scope="col">Frasco #</th>
                              <th scope="col">Conc. / Vol. alérgeno</th>
                              <th scope="col">Alérgenos / Vial</th>
                              <th scope="col">Vol. Total</th>
                              <th scope="col">Diluyente</th>
                              <th scope="col">Última Aplicación</th>
                              <th scope="col">Próxima Aplicación</th>
                            </tr>
                          </thead>
                          <tbody className="table-group-divider">
                          {(isFetching)
                          ? <tr className='noBorder noHover noPointer'><td colSpan={6}><Loader /></td></tr>
                          : actuales.map(inmuno => <tr key={ inmuno._id }>
                              <td>{ inmuno.tipo }</td>
                              <td>{ inmuno.identificador }</td>
                              <td>{ inmuno.concentracion } ({ inmuno.volumenAlergeno })</td>
                              <td>{ inmuno.alergenos.map(alerg => alerg.nombre).join(', ') }</td>
                              <td>{ inmuno.volumenTotal } { inmuno.tipoDosis }</td>
                              <td>{ (inmuno.tipo === 'Subcutánea' || inmuno.tipo === 'Sublingual') ? `${ inmuno.diluyente } ${ inmuno.tipoDosis }` : '-' }</td>
                              <td>{ (inmuno.ultimaAplicacionDosis !== null) ? `${ inmuno.ultimaAplicacionDosis } ${ inmuno.tipoDosis } - ${ inmuno.ultimaAplicacionFechaFormato }` : '-' }</td>
                              <td>{ (inmuno.proximaAplicacionDosis !== null) ? `${ inmuno.proximaAplicacionDosis } ${ inmuno.tipoDosis } - ${ inmuno.proximaAplicacionFechaFormato }` : '-' }</td>
                            </tr>) }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col">
                      <div className={styles.tableHeader}>Historial de inmunoterapias</div>
                      <div className="table-responsive">
                        <table className="table align-middle">
                          <thead>
                            <tr>
                              <th scope="col">ITE</th>
                              <th scope="col">Frasco #</th>
                              <th scope="col">Conc. / Vol. alérgeno</th>
                              <th scope="col">Alérgenos/Vial</th>
                              <th scope="col">Vol. Total</th>
                              <th scope="col">Diluyente</th>
                              <th scope="col">Última Aplicación</th>
                            </tr>
                          </thead>
                          <tbody className="table-group-divider">
                          {(isFetching)
                          ? <tr className='noBorder noHover noPointer'><td colSpan={6}><Loader /></td></tr>
                          : historicas.map(inmuno => <tr key={ inmuno._id }>
                              <td>{ inmuno.tipo }</td>
                              <td>{ inmuno.identificador }</td>
                              <td>{ inmuno.concentracion } ({ inmuno.volumenAlergeno })</td>
                              <td>{ inmuno.alergenos.map(alerg => alerg.nombre).join(', ') }</td>
                              <td>{ inmuno.volumenTotal } { inmuno.tipoDosis }</td>
                              <td>{ (inmuno.tipo === 'Subcutánea' || inmuno.tipo === 'Sublingual') ? `${ inmuno.diluyente } ${ inmuno.tipoDosis }` : '-' }</td>
                              <td>{ (inmuno.ultimaAplicacionDosis !== null) ? `${ inmuno.ultimaAplicacionDosis } ${ inmuno.tipoDosis } - ${ inmuno.ultimaAplicacionFechaFormato }` : '-' }</td>
                            </tr>) }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              }
            </>
          }
      </div>
    </div>
  )
}
