import { useQuery } from "@tanstack/react-query"

import { getInmunoterapias } from "../services/inmunoterapias.service";

export const usePacienteInmunoterapias = (id) => {

    const pacienteInmunoterapiasQuery = useQuery({
        queryKey: ['paciente/inmunoterapia/index', id],
        queryFn: () => getInmunoterapias(id),
        staleTime: 0, // nulo
        placeholderData: {
            actuales: [],
            historicas: []
        }
    });

    return {
        pacienteInmunoterapiasQuery,
    }
}