import { useQuery } from "@tanstack/react-query"

import { getFrascoData } from "../services/inmunoterapia.service";

export const useInmunoterapiaFrascoData = (id, tipo, fase, frasco, fecha, lado) => {

    const frascoDataQuery = useQuery({
        queryKey: ['inmunoterapiaFrascoData'],
        queryFn: () => getFrascoData(id, tipo, fase, frasco, fecha, lado),
        staleTime: 0, // nulo
    });

    return {
        frascoDataQuery,
    }
}