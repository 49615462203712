import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'

import { FrascoForm } from './components/Frasco';
import { PrimaryRoundedButton } from '../..'

export const MultiFormFrascosSubcutanea = ({ formsStatus='checked', name, onFormsChange, setFormValidation, value }) => {

    const [elements, setElements] = useState((typeof value !== 'undefined') ? value.map(val => ({ id: val.id, frasco: val.frasco, concentracion: val.concentracion, volumenAlergeno: val.volumenAlergeno, volumenTotal: val.volumenTotal, validation: true })) : []);

    const [resetFields, setResetFields] = useState(true);

    const handleAddElement = () => {
        setElements([...elements, { id: moment().unix() + Math.random(), frasco: String(parseInt(elements.length) + 1), concentracion: '0', volumenAlergeno: 0.00, volumenTotal: 0.00, validation: false }]);
    }

    const handleRemoveElement = (id) => {
        let newElements = elements.filter(element => element.id !== id).map((element, index) => ({ ...element, frasco: String(index + 1) }));
        setElements(newElements);
        setResetFields(false);
        setTimeout(() => {
            setResetFields(true);
        }, 1);
    }

    const handleSetElementData = (data) => {
        setElements(elements.map(element => {
            if(element.id === data.id){
                return data;
            }else{
                return element;
            }
        }));
    }

    useEffect(() => {
        onFormsChange({ target: { name: name, value: elements.map(element => ({
            id: element.id,
            frasco: element.frasco,
            concentracion: element.concentracion,
            volumenAlergeno: element.volumenAlergeno,
            volumenTotal: element.volumenTotal,
        })) } });
        let errorElements = elements.filter(element => (!element.validation));
        setFormValidation(name, (errorElements.length === 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements])

    return (
        <>
            {(resetFields) && 
            elements.map((element, index) => <FrascoForm
                key={element.id}
                element={element}
                removeElement={handleRemoveElement}
                onFormChange={handleSetElementData}
                first={index === 0}
                formStatus={formsStatus}
            />)
            }
            <div className='centrar'>
                <PrimaryRoundedButton
                    icon="Plus"
                    onClick={handleAddElement}
                    type="button"
                />
            </div>
        </>
    )
}

MultiFormFrascosSubcutanea.propTypes = {
    formsStatus: PropTypes.oneOf(['checked', 'checking']),
    name: PropTypes.string.isRequired,
    onFormsChange: PropTypes.func.isRequired,
    setFormValidation: PropTypes.func,
    value: PropTypes.array
}