import { inmunetApi } from '../../../api';

export const getMotivo = async() => {

    try {

        const { data } = await inmunetApi.get(`/motivoCita`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.motivosCita;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};