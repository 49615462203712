export const formatDate = (day, month, year) => {
    const months = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    // Convertir el mes (string) a número y obtener el nombre del mes
    const monthIndex = parseInt(month, 10) - 1;
    const monthName = months[monthIndex];

    return `${monthName} ${parseInt(day, 10)}, ${year}`;
}