import { inmunetApi } from '../../../../api';

export const getPacienteGraficaCuestionario = async(idPaciente, cuestionario, pag) => {

    const params = new URLSearchParams();
    params.append('pag', `${pag}`);

    let cuestionario_str = '';
    switch (cuestionario) {
        case 1:
            cuestionario_str = 'rinitis';
            break;
        case 2:
            cuestionario_str = 'urticaria';
            break;
        case 3:
            cuestionario_str = 'asma';
            break;
        case 4:
            cuestionario_str = 'poem';
            break;

        default:
            break;
    }

    try {

        const { data } = await inmunetApi.post(`cuestionario/grafica/${ cuestionario_str }/${ idPaciente }`, {}, {
            params,
        });

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};