import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { usePacienteInmunoterapiaAplicacionCrear } from '../hooks/usePacienteInmunoterapiaAplicacionCrear';
import { usePacienteInmunoterapiaAplicacionEditar } from '../hooks/usePacienteInmunoterapiaAplicacionEditar';
import { usePacienteInmunoterapiaAplicacionAplicar } from '../hooks/usePacienteInmunoterapiaAplicacionAplicar';
import { usePacienteInmunoterapiaAplicacionPosponer } from '../hooks/usePacienteInmunoterapiaAplicacionPosponer';
import { usePacienteInmunoterapiaAplicacionEliminar } from '../hooks/usePacienteInmunoterapiaAplicacionEliminar';
import { usePacienteInmunoterapiaFinalizar } from '../hooks/usePacienteInmunoterapiaFinalizar';

import { ModalContainer, MultiFormAplicacionInmunoterapiaActiva, PrimaryButton, TextInputPreview } from '../../../components';
import { PushInmunoterapiaModal } from '../../Modals/PushInmunoterapiaModal';
import { NotificacionInmunoterapiaModal } from '../../Modals/NotificacionInmunoterapiaModal';
import { CaducidadInmunoterapiaModal } from '../../Modals/CaducidadInmunoterapia';
import { LimiteInmunoterapiaModal } from '../../Modals/LimiteInmunoterapia';

import styles from '../PacienteConsulta.module.css';

export const InmunoterapiaActiva = ({ inmunoterapia, paciente }) => {

    const { createInmunoterapiaAplicacionMutation: { mutate: createInmunoterapiaAplicacion } } = usePacienteInmunoterapiaAplicacionCrear(paciente);

    const { updateInmunoterapiaAplicacionMutation: { mutate: updateInmunoterapiaAplicacion } } = usePacienteInmunoterapiaAplicacionEditar(paciente);

    const { applyInmunoterapiaAplicacionMutation: { mutate: applyInmunoterapiaAplicacion } } = usePacienteInmunoterapiaAplicacionAplicar(paciente);

    const { delayInmunoterapiaAplicacionMutation: { mutate: delayInmunoterapiaAplicacion } } = usePacienteInmunoterapiaAplicacionPosponer(paciente);

    const { deleteInmunoterapiaAplicacionMutation: { mutate: deleteInmunoterapiaAplicacion } } = usePacienteInmunoterapiaAplicacionEliminar(paciente);

    const { finishInmunoterapiaMutation: { mutate: finishInmunoterapia } } = usePacienteInmunoterapiaFinalizar(paciente);

    const [aplicaciones, setAplicaiones] = useState(inmunoterapia.aplicaciones);

    const [toDoAccion, setToDoAccion] = useState(0);

    const [toDoAccionAplicacion, setToDoAccionAplicacion] = useState(0);

    const [toDoAccionData, setToDoAccionData] = useState({});

    const [reprogramar, setReprogramar] = useState(null);

    const [notificacion, setNotificacion] = useState(false);

    const [notificacionMsg, setNotificacionMsg] = useState('');
    
    const [caducidad, setCaducidad] = useState(false);

    const [limite, setLimite] = useState(false);

    const [statusPushModal, setStatusPushModal] = useState(false);
    
    const [statusNotificacionModal, setStatusNotificacionModal] = useState(false);

    const [statusCaducidadModal, setStatusCaducidadModal] = useState(false);

    const [statusLimiteModal, setStatusLimiteModal] = useState(false);

    const showPushModal = () => {
        setStatusPushModal(true);
    }

    const aceptPushModal = () => {
        setReprogramar(true);
        setStatusPushModal(false);
    }
    
    const rejectPushModal = () => {
        setReprogramar(false);
        setStatusPushModal(false);
    }

    const closePushModal = () => {
        setStatusPushModal(false);
    }
    
    const showNotificacionModal = (msg) => {
        setNotificacionMsg(msg);
        setStatusNotificacionModal(true);
    }

    const aceptNotificacionModal = () => {
        setNotificacion(true);
        setStatusNotificacionModal(false);
    }
    
    const rejectNotificacionModal = () => {
        setNotificacion(false);
        setStatusNotificacionModal(false);
    }
    
    const showCaducidadModal = () => {
        setStatusCaducidadModal(true);
    }

    const aceptCaducidadModal = () => {
        setCaducidad(true);
        setStatusCaducidadModal(false);
    }
    
    const rejectCaducidadModal = () => {
        setCaducidad(false);
        setStatusCaducidadModal(false);
    }

    const showLimiteModal = () => {
        setStatusLimiteModal(true);
    }

    const aceptLimiteModal = () => {
        setLimite(true);
        setStatusLimiteModal(false);
    }
    
    const rejectLimiteModal = () => {
        setLimite(false);
        setStatusLimiteModal(false);
    }

    const handleCrearAplicacion = () => {
        setToDoAccion(1);
        createInmunoterapiaAplicacion({ data: { caducidad, limite }, id: inmunoterapia._id, success:  setAplicaiones, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
            setToDoAccion(0);
            setToDoAccionAplicacion(0);
            setToDoAccionData({});
        }});
    }

    const handleEliminarAplicacion = (aplicacion) => {
        setToDoAccion(2);
        setToDoAccionAplicacion(aplicacion);
        deleteInmunoterapiaAplicacion({ data: { caducidad, limite }, id: inmunoterapia._id, aplicacion: aplicacion, success:  setAplicaiones, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
            setToDoAccion(0);
            setToDoAccionAplicacion(0);
            setToDoAccionData({});
        }});
    }

    const handleUpdateAplicacion = (data) => {
        if(typeof data.fecha !== 'undefined'){
            if(data.last){
                setToDoAccion(5);
                setToDoAccionAplicacion(data.id);
                setToDoAccionData({ fecha: data.fecha });
                setReprogramar(false);
                delayInmunoterapiaAplicacion({ data: { fecha: data.fecha, reprogramar: false, notificacion, caducidad, limite }, id: inmunoterapia._id, aplicacion: data.id, success:  setAplicaiones, notificacion: showNotificacionModal, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                    setToDoAccion(0);
                    setToDoAccionAplicacion(0);
                    setToDoAccionData({});
                    setNotificacion(false);
                    setNotificacionMsg('');
                    setReprogramar(null);
                }});
            }else{
                setToDoAccion(5);
                setToDoAccionAplicacion(data.id);
                setToDoAccionData({ fecha: data.fecha });
                setTimeout(() => {
                    document.activeElement.blur();
                    showPushModal();
                }, 1);
            }
        }
        if(typeof data.dosis !== 'undefined' || typeof data.ladoAplicacion !== 'undefined' || typeof data.reaccion !== 'undefined'){
            setToDoAccion(3);
            setToDoAccionAplicacion(data.id);
            setToDoAccionData({ dosis: data.dosis, ladoAplicacion: data.ladoAplicacion, reaccion: data.reaccion });
            updateInmunoterapiaAplicacion({ data: { dosis: data.dosis, ladoAplicacion: data.ladoAplicacion, reaccion: data.reaccion, caducidad, limite }, id: inmunoterapia._id, aplicacion: data.id, success:  setAplicaiones, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                setToDoAccion(0);
                setToDoAccionAplicacion(0);
                setToDoAccionData({});
            }});
        }
        if(typeof data.realizado !== 'undefined'){
            setToDoAccion(4);
            setToDoAccionAplicacion(data.id);
            setToDoAccionData({ realizado: data.realizado });
            applyInmunoterapiaAplicacion({ data: { realizado: data.realizado, notificacion, caducidad, limite }, id: inmunoterapia._id, aplicacion: data.id, success:  setAplicaiones, notificacion: showNotificacionModal, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                setToDoAccion(0);
                setToDoAccionAplicacion(0);
                setToDoAccionData({});
                setNotificacion(false);
                setNotificacionMsg('');
            }});
        }
    }

    useEffect(() => {
        if(toDoAccion === 5){
            delayInmunoterapiaAplicacion({ data: { ...toDoAccionData, reprogramar, notificacion, caducidad, limite }, id: inmunoterapia._id, aplicacion: toDoAccionAplicacion, success:  setAplicaiones, notificacion: showNotificacionModal, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                setToDoAccion(0);
                setToDoAccionAplicacion(0);
                setToDoAccionData({});
                setNotificacion(false);
                setNotificacionMsg('');
                setReprogramar(null);
            }});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reprogramar]);

    useEffect(() => {
        switch (toDoAccion) {
            case 4:
                applyInmunoterapiaAplicacion({ data: { ...toDoAccionData, notificacion, caducidad, limite }, id: inmunoterapia._id, aplicacion: toDoAccionAplicacion, success:  setAplicaiones, notificacion: showNotificacionModal, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                    setToDoAccion(0);
                    setToDoAccionAplicacion(0);
                    setToDoAccionData({});
                    setNotificacion(false);
                    setNotificacionMsg('');
                }});
                break;
            case 5:
                delayInmunoterapiaAplicacion({ data: { ...toDoAccionData, reprogramar, notificacion, caducidad, limite }, id: inmunoterapia._id, aplicacion: toDoAccionAplicacion, success:  setAplicaiones, notificacion: showNotificacionModal, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                    setToDoAccion(0);
                    setToDoAccionAplicacion(0);
                    setToDoAccionData({});
                    setNotificacion(false);
                    setNotificacionMsg('');
                    setReprogramar(null);
                }});
                break;
        
            default:
                break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificacion]);
    
    useEffect(() => {
        switch (toDoAccion) {
            case 1:
                createInmunoterapiaAplicacion({ data: { caducidad, limite }, id: inmunoterapia._id, success:  setAplicaiones, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                    setToDoAccion(0);
                    setToDoAccionAplicacion(0);
                    setToDoAccionData({});
                }});
                break;
            case 2:
                deleteInmunoterapiaAplicacion({ data: { caducidad, limite }, id: inmunoterapia._id, aplicacion: toDoAccionAplicacion, success:  setAplicaiones, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                    setToDoAccion(0);
                    setToDoAccionAplicacion(0);
                    setToDoAccionData({});
                }});
                break;
            case 3:
                updateInmunoterapiaAplicacion({ data: { ...toDoAccionData, caducidad, limite }, id: inmunoterapia._id, aplicacion: toDoAccionAplicacion, success:  setAplicaiones, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                    setToDoAccion(0);
                    setToDoAccionAplicacion(0);
                    setToDoAccionData({});
                }});
                break;
            case 4:
                applyInmunoterapiaAplicacion({ data: { ...toDoAccionData, notificacion, caducidad, limite }, id: inmunoterapia._id, aplicacion: toDoAccionAplicacion, success:  setAplicaiones, notificacion: showNotificacionModal, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                    setToDoAccion(0);
                    setToDoAccionAplicacion(0);
                    setToDoAccionData({});
                    setNotificacion(false);
                    setNotificacionMsg('');
                }});
                break;
            case 5:
                delayInmunoterapiaAplicacion({ data: { ...toDoAccionData, reprogramar, notificacion, caducidad, limite }, id: inmunoterapia._id, aplicacion: toDoAccionAplicacion, success:  setAplicaiones, notificacion: showNotificacionModal, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                    setToDoAccion(0);
                    setToDoAccionAplicacion(0);
                    setToDoAccionData({});
                    setNotificacion(false);
                    setNotificacionMsg('');
                    setReprogramar(null);
                }});
                break;
        
            default:
                break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [caducidad]);

    useEffect(() => {
        switch (toDoAccion) {
            case 1:
                createInmunoterapiaAplicacion({ data: { caducidad, limite }, id: inmunoterapia._id, success:  setAplicaiones, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                    setToDoAccion(0);
                    setToDoAccionAplicacion(0);
                    setToDoAccionData({});
                }});
                break;
            case 2:
                deleteInmunoterapiaAplicacion({ data: { caducidad, limite }, id: inmunoterapia._id, aplicacion: toDoAccionAplicacion, success:  setAplicaiones, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                    setToDoAccion(0);
                    setToDoAccionAplicacion(0);
                    setToDoAccionData({});
                }});
                break;
            case 3:
                updateInmunoterapiaAplicacion({ data: { ...toDoAccionData, caducidad, limite }, id: inmunoterapia._id, aplicacion: toDoAccionAplicacion, success:  setAplicaiones, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                    setToDoAccion(0);
                    setToDoAccionAplicacion(0);
                    setToDoAccionData({});
                }});
                break;
            case 4:
                applyInmunoterapiaAplicacion({ data: { ...toDoAccionData, notificacion, caducidad, limite }, id: inmunoterapia._id, aplicacion: toDoAccionAplicacion, success:  setAplicaiones, notificacion: showNotificacionModal, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                    setToDoAccion(0);
                    setToDoAccionAplicacion(0);
                    setToDoAccionData({});
                    setNotificacion(false);
                    setNotificacionMsg('');
                }});
                break;
            case 5:
                delayInmunoterapiaAplicacion({ data: { ...toDoAccionData, reprogramar, notificacion, caducidad, limite }, id: inmunoterapia._id, aplicacion: toDoAccionAplicacion, success:  setAplicaiones, notificacion: showNotificacionModal, caducidad: showCaducidadModal, limite: showLimiteModal, resetAccion: () => {
                    setToDoAccion(0);
                    setToDoAccionAplicacion(0);
                    setToDoAccionData({});
                    setNotificacion(false);
                    setNotificacionMsg('');
                    setReprogramar(null);
                }});
                break;
        
            default:
                break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limite]);

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className={styles.estudioContainer}>
                        <div className="row mt-1 mb-4">
                            <div className="col">
                                <span className={styles.tabsSubHeader}>Datos generales</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-3">
                                <TextInputPreview
                                    label="Inmunoterapia"
                                    name="tipo"
                                    value={(typeof inmunoterapia.tipo !== 'undefined') ? inmunoterapia.tipo : ''}
                                />
                            </div>
                            <div className="col-md-12 col-lg-9">
                                <TextInputPreview
                                    label="Alérgenos"
                                    name="alergenos"
                                    value={(typeof inmunoterapia.alergenos !== 'undefined') ? inmunoterapia.alergenos.map(aler => aler.nombre).join(', ') : ''}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-3">
                                <TextInputPreview
                                    label="Fase"
                                    name="fase"
                                    value={(typeof inmunoterapia.fase !== 'undefined') ? inmunoterapia.fase : ''}
                                />
                            </div>
                            <div className="col-md-12 col-lg-3">
                                <TextInputPreview
                                    label="Frasco"
                                    name="frasco"
                                    value={(typeof inmunoterapia.frasco !== 'undefined') ? inmunoterapia.frasco : ''}
                                />
                            </div>
                            <div className="col-md-12 col-lg-3">
                                <TextInputPreview
                                    label="Concentración"
                                    name="concentracion"
                                    value={(typeof inmunoterapia.concentracion !== 'undefined') ? inmunoterapia.concentracion : ''}
                                />
                            </div>
                            {(inmunoterapia.tipo !== 'Alxoid') &&
                            <div className="col-md-12 col-lg-3">
                                <TextInputPreview
                                    label={`Vol. alérgeno (${ (typeof inmunoterapia.tipoDosis !== 'undefined') ? inmunoterapia.tipoDosis : '' })`}
                                    name="volumenAlergeno"
                                    value={(typeof inmunoterapia.volumenAlergeno !== 'undefined') ? String(inmunoterapia.volumenAlergeno) : ''}
                                />
                            </div>
                            }
                        </div>
                        <div className="row mt-1 mb-4">
                            <div className="col">
                                <span className={styles.tabsSubHeader}>Aplicaciones</span>
                            </div>
                        </div>
                        <div className="row mt-1 mb-4">
                            <div className="col">
                                <MultiFormAplicacionInmunoterapiaActiva
                                    handleCrear={handleCrearAplicacion}
                                    handleEliminar={handleEliminarAplicacion}
                                    handleUpdate={handleUpdateAplicacion}
                                    ladoFlag={inmunoterapia.ladoAplicacion}
                                    lados={inmunoterapia.ladosAplicacion}
                                    reaccionFlag={inmunoterapia.reaccion}
                                    tipo={inmunoterapia.tipo}
                                    value={aplicaciones}
                                />
                            </div>
                        </div>
                        {(inmunoterapia.conclucionPendiente) &&
                        <>
                            <div className="row">
                                <div className="col">
                                    <PrimaryButton
                                        block={true}
                                        icon="FolderCheck"
                                        iconMargin={5}
                                        inverse={true}
                                        marginTop={false}
                                        onClick={ () => finishInmunoterapia(inmunoterapia._id) }
                                        text="Finalizar"
                                        type="button"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="text-center">Pendiente de finalizar</div>
                                </div>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>
            {(statusPushModal) &&
            <ModalContainer
                state={ statusPushModal }
                hide={ closePushModal }
                content={ <PushInmunoterapiaModal acept={aceptPushModal} reject={rejectPushModal} /> }
            />
            }
            {(statusNotificacionModal) &&
            <ModalContainer
                state={ statusNotificacionModal }
                hide={ rejectNotificacionModal }
                content={ <NotificacionInmunoterapiaModal acept={aceptNotificacionModal} msg={notificacionMsg} reject={rejectNotificacionModal} /> }
            />
            }
            {(statusCaducidadModal) &&
            <ModalContainer
                state={ statusCaducidadModal }
                hide={ rejectCaducidadModal }
                content={ <CaducidadInmunoterapiaModal acept={aceptCaducidadModal} reject={rejectCaducidadModal} /> }
            />
            }
            {(statusLimiteModal) &&
            <ModalContainer
                state={ statusLimiteModal }
                hide={ rejectLimiteModal }
                content={ <LimiteInmunoterapiaModal acept={aceptLimiteModal} reject={rejectLimiteModal} /> }
            />
            }
        </>
    )
}

InmunoterapiaActiva.propTypes = {
    inmunoterapia: PropTypes.object.isRequired,
    paciente: PropTypes.string.isRequired,
}