import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { updateCita } from "../services/cita.service";

import { successNotification, dangerNotification } from '../../../../redux/slices/ui';

export const useCitaEditar = (id) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const updateCitaMutation = useMutation({
        mutationFn: updateCita,
        onSuccess: ({ inactivoFlag, horarioFlag, empalmadaFlag, cita }, variables, context) => {
            if(inactivoFlag){
                variables.onInactivo();
            }else if(horarioFlag){
                variables.onHorario();
            }else if(empalmadaFlag){
                variables.onEmpalmada();
            }else{
                queryClient.invalidateQueries({ queryKey: ['citas', id] });
                variables.onComplete();
                variables.onSuccess();
                dispatch(successNotification('Cita editada'));
            }

        },
        onError: (error, variables, context) => {
            variables.onComplete();
            dispatch(dangerNotification(error.message));
            // dispatch(dangerNotification(error.msg.message));
        }
    });

    return {
        updateCitaMutation,
    }
}