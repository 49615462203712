import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { toggleCuestionarioStatus } from "../services/pacientecuestionarios.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacienteCuestionariosStatusToggle = (id) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const toggleCuestionarioStatusMutation = useMutation({
        mutationFn: toggleCuestionarioStatus,
        onSuccess: (cuestionario, variables, context) => {
            queryClient.setQueryData(['paciente/cuestionarios', id], oldData => {

                let updateData = {};
                switch (variables.noCuestionario) {
                    case 1:
                        updateData = {
                            rinitis: cuestionario
                        }
                        break;
                    case 2:
                        updateData = {
                            urticaria: cuestionario
                        }
                        break;
                    case 3:
                        updateData = {
                            asma: cuestionario
                        }
                        break;
                    case 4:
                        updateData = {
                            poem: cuestionario
                        }
                        break;
                
                    default:
                        break;
                }

                return {
                    ...oldData,
                    ...updateData
                };
                
            });
            dispatch(successNotification((variables.status === 'true') ? 'Cuestionario activado' : 'Cuestionario desactivado'));
        },
        onError: (error, variables, context) => {
            variables.onComplete();
            dispatch(dangerNotification(error.message));
            // dispatch(dangerNotification(error.msg.message));
        }
    });

    return {
        toggleCuestionarioStatusMutation,
    }
}