import { inmunetApi } from '../../../api';

export const getPacienteAntecedentes = async(id) => {

    try {

        const { data } = await inmunetApi.get(`/paciente/antecedentes/${id}`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.antecedentes;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const updatePacienteAntecedentes = async({ data: antecedentes, id }) => {

    try {

        const { data } = await inmunetApi.put(`/paciente/antecedentes/${id}`, {...antecedentes });

        if (!data.status) throw (data.msg) ? data.msg : 'Error al actualizar datos';

        return data.antecedentes;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error('Error al actualizar datos');
        }

    }

};