import { useEffect } from "react";
import PropTypes from 'prop-types';

import { useForm, useFormValidation } from "../../../../hooks";

import { IconButton, ReactSelectSingleKeyInput, TextInput, TextInputPreview } from "../../.."

import { colors } from "../../../../types/types";

import styles from '../MultiFormFrascos.module.css';

export const FrascoForm = ({ element, first, formStatus='checked', onFormChange, removeElement }) => {

  const { frasco, concentracion, volumenTotal, onInputChange } = useForm({ frasco: element.frasco, concentracion: element.concentracion, volumenTotal: element.volumenTotal });

  const { isFormValid, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'frasco', default: (element.frasco !== '')}, {field: 'concentracion', default: true}, {field: 'volumenTotal', default: true}]);

  useEffect(() => {
    if(onFormChange) onFormChange({ id: element.id, frasco, concentracion, volumenTotal, validation: isFormValid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frasco, concentracion, volumenTotal, isFormValid])

  useEffect(() => {
    if(formStatus === 'checking') startFormValidation();
  }, [formStatus, startFormValidation]);

  return (
    <div className="row">
        <div className="col-md-12 col-lg-2">
            <TextInputPreview
                label="Identificador"
                name="frasco"
                value={ frasco }
            />
        </div>
        <div className="col-md-12 col-lg-4">
            <TextInput
                formFieldStatus={ formFields['concentracion'].status }
                label="Concentración"
                name="concentracion"
                onChange={ onInputChange }
                required={ false }
                requiredIndicator={ false }
                setFormFieldValidation={ setFormFieldValidation }
                value={ concentracion }
            />
        </div>
        <div className="col-md-12 col-lg-4">
            <ReactSelectSingleKeyInput
                formFieldStatus={ formFields['volumenTotal'].status }
                label="Vol. total (ml)"
                name="volumenTotal"
                onChange={ onInputChange }
                required={true}
                requiredIndicator={false}
                setFormFieldValidation={ setFormFieldValidation }
                value={volumenTotal}
                values={[{ value: '6.0', label: '6.0' }, { value: '9.0', label: '9.0' }]}
            />
        </div>
        <div className={"col-md-12 col-lg-2"}>
            {(!first) &&
            <div className={styles.closeBtnContainer}>
                <div>
                    <label htmlFor="">&nbsp;</label>
                    <IconButton
                        icon="Trash"
                        iconColor={colors.DANGER}
                        marginTop={false}
                        onClick={() => removeElement(element.id)}
                        type="button"
                        size={28}
                    />
                </div>
            </div>
            }
        </div>
    </div>
  )
}

FrascoForm.propTypes = {
  element: PropTypes.any.isRequired,
  first: PropTypes.bool.isRequired,
  formStatus: PropTypes.oneOf(['checked', 'checking']),
  onFormChange: PropTypes.func,
  removeElement: PropTypes.func.isRequired,
}