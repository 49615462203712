import PropTypes from 'prop-types';
import moment from 'moment';

import { useForm, useFormValidation } from '../../../hooks';
import { useConsultorios } from '../hooks/useConsultorios';

import { CheckboxInput, ColorInput, DateInput, EmailInput, IconButton, ImgCircledInput, LightButton, MultiFormMotivosConsulta, PrimaryButton, ReactSelectSingleInput, TelInput, TextInput, ZipCodeInput } from "../../../components"
import { MultiFormHorario } from '../../../components/Forms/MultiFormHorario';

import { eliminarUndefined } from '../../../helpers';

import styles from '../Consultorios.module.css';
import { colors } from '../../../types/types';

export const ConsultorioUpdate = ({ consultorio, configuracion, handleCancelarEditarConsultorio }) => {

  const {
    updateConsultorioMutation: { mutate: updateConsultorio, isPending },
    updateConfiguracionConsultorioCrearInactivoMutation: { mutate: crearInactivo },
    updateConfiguracionConsultorioEliminarInactivoMutation: { mutate: eliminarInactivo },
  } = useConsultorios();

  let lunesAux = configuracion.horario.lunes.map((horario, index) => ({ _id: (moment().unix() + index), ...horario }));
  let martesAux = configuracion.horario.martes.map((horario, index) => ({ _id: (moment().unix() + index), ...horario }));
  let miercolesAux = configuracion.horario.miercoles.map((horario, index) => ({ _id: (moment().unix() + index), ...horario }));
  let juevesAux = configuracion.horario.jueves.map((horario, index) => ({ _id: (moment().unix() + index), ...horario }));
  let viernesAux = configuracion.horario.viernes.map((horario, index) => ({ _id: (moment().unix() + index), ...horario }));
  let sabadoAux = configuracion.horario.sabado.map((horario, index) => ({ _id: (moment().unix() + index), ...horario }));
  let domingoAux = configuracion.horario.domingo.map((horario, index) => ({ _id: (moment().unix() + index), ...horario }));

  const { nombre, direccion, info, colonia, municipio, entidadFederativa, pais, codigoPostal, email, telefono, celular, principal, color, img, activo, lunes, martes, miercoles, jueves, viernes, sabado, domingo, motivos, onInputChange } = useForm({ nombre: consultorio.nombre, direccion: consultorio.direccion, info: (typeof consultorio.info !== 'undefined') ? consultorio.info : '', colonia: consultorio.colonia, municipio: consultorio.municipio, entidadFederativa: consultorio.entidadFederativa, pais: consultorio.pais, codigoPostal: consultorio.codigoPostal, email: consultorio.email, telefono: (typeof consultorio.telefono !== 'undefined') ? consultorio.telefono : '', celular: consultorio.celular, principal: consultorio.principal, color: consultorio.color, img: consultorio.img, activo: consultorio.activo, lunes: lunesAux, martes: martesAux, miercoles: miercolesAux, jueves: juevesAux, viernes: viernesAux, sabado: sabadoAux, domingo: domingoAux, motivos: configuracion.motivos });

  const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'nombre', default: true}, {field: 'direccion', default: true}, {field: 'info', default: true}, {field: 'colonia', default: true}, {field: 'municipio', default: true}, {field: 'entidadFederativa', default: true}, {field: 'pais', default: true}, {field: 'codigoPostal', default: true}, {field: 'email', default: true}, {field: 'telefono', default: true}, {field: 'celular', default: true}, {field: 'principal', default: true}, {field: 'color', default: true}, {field: 'img', default: true}, {field: 'activo', default: true}, {field: 'lunes', default: true}, {field: 'martes', default: true}, {field: 'miercoles', default: true}, {field: 'jueves', default: true}, {field: 'viernes', default: true}, {field: 'sabado', default: true}, {field: 'domingo', default: true}, {field: 'motivos', default: true}]);

  const { fecha, onInputChange: onInputChangeInactivo, onResetForm } = useForm({ fecha: '' });

  const { isFormValid: isFormValidInactivo, formValidationStatus: formValidationStatusInactivo, formFields: formFieldsInactivo, startFormValidation: startFormValidationInactivo, setFormFieldValidation: setFormFieldValidationInactivo } = useFormValidation([{field: 'fecha', default: false}]);

  const onSubmit = async ( event ) => {
    event.preventDefault();
    
    if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
      updateConsultorio({
        consultorio: eliminarUndefined({ nombre, direccion, info, colonia, municipio, entidadFederativa, pais, codigoPostal, email, telefono, celular, principal, color, img, activo }, { info: consultorio.info, telefono: consultorio.telefono }, ['img']),
        horario: { lunes: lunes.map(data => ({ horaInicio: data.horaInicio, minutoInicio: data.minutoInicio, horaFin: data.horaFin, minutoFin: data.minutoFin })), martes: martes.map(data => ({ horaInicio: data.horaInicio, minutoInicio: data.minutoInicio, horaFin: data.horaFin, minutoFin: data.minutoFin })), miercoles: miercoles.map(data => ({ horaInicio: data.horaInicio, minutoInicio: data.minutoInicio, horaFin: data.horaFin, minutoFin: data.minutoFin })), jueves: jueves.map(data => ({ horaInicio: data.horaInicio, minutoInicio: data.minutoInicio, horaFin: data.horaFin, minutoFin: data.minutoFin })), viernes: viernes.map(data => ({ horaInicio: data.horaInicio, minutoInicio: data.minutoInicio, horaFin: data.horaFin, minutoFin: data.minutoFin })), sabado: sabado.map(data => ({ horaInicio: data.horaInicio, minutoInicio: data.minutoInicio, horaFin: data.horaFin, minutoFin: data.minutoFin })), domingo: domingo.map(data => ({ horaInicio: data.horaInicio, minutoInicio: data.minutoInicio, horaFin: data.horaFin, minutoFin: data.minutoFin })) },
        motivos: { motivos: motivos.map(data => ({ _id: data._id, duracion: data.duracion })) },
        id: consultorio._id,
        callback: handleCancelarEditarConsultorio
      });
    }else{
      startFormValidation();
    }
  }

  const handleSubmitInactivo = () => {
    if(formValidationStatusInactivo === 'checked' && isFormValidInactivo != null && isFormValidInactivo && fecha !== ''){
      crearInactivo({ data: { fecha }, id: consultorio._id, callback: onResetForm });
    }else{
      startFormValidationInactivo();
    }
  }

  const handleEliminarInactivo = (inactivo) => {
    eliminarInactivo({ id: consultorio._id, inactivo: inactivo });
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col">
            <div className={styles.subHeader}>Datos de consultorio</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-2">
            <ImgCircledInput
              formFieldStatus={ formFields['img'].status }
              imgText="Logo"
              label="Logotipo"
              name="img"
              onChange={ onInputChange }
              required={false}
              requiredIndicator={false}
              setFormFieldValidation={ setFormFieldValidation }
              value={img}
            />
          </div>
          <div className="col-md-12 col-lg-10">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <TextInput
                  formFieldStatus={ formFields['nombre'].status }
                  label="Nombre del consultorio"
                  name="nombre"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ nombre }
                />
              </div>
              <div className="col-sm-12 col-md d-flex align-items-center">
                <CheckboxInput
                  label="Consultorio principal"
                  name="principal"
                  onChange={ onInputChange }
                  required={false}
                  checked={ (principal === 'true' || principal === true) ? true : false }
                />
              </div>
              <div className="col-sm-12 col-md d-flex align-items-center">
                <CheckboxInput
                  label="Consultorio activo"
                  name="activo"
                  onChange={ onInputChange }
                  required={false}
                  checked={ (activo === 'true' || activo === true) ? true : false }
                />
              </div>
              <div className="col-sm-12 col-md">
                <ColorInput
                  formFieldStatus={ formFields['color'].status }
                  label="Color de tema"
                  name="color"
                  onChange={ onInputChange }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ color }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md">
                <TextInput
                  formFieldStatus={ formFields['direccion'].status }
                  label="Dirección"
                  name="direccion"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ direccion }
                />
              </div>
              <div className="col-sm-12 col-md">
                <TextInput
                  formFieldStatus={ formFields['info'].status }
                  label="Info. adicional (Piso, # Consultorio, etc.)"
                  name="info"
                  onChange={ onInputChange }
                  required={ false }
                  requiredIndicator={ false }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ info }
                />
              </div>
              <div className="col-sm-12 col-md">
                <TextInput
                  formFieldStatus={ formFields['colonia'].status }
                  label="Colonia"
                  name="colonia"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ colonia }
                />
              </div>
              <div className="col-sm-12 col-md">
                <TextInput
                  formFieldStatus={ formFields['municipio'].status }
                  label="Municipio"
                  name="municipio"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ municipio }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md">
                <ReactSelectSingleInput
                  formFieldStatus={ formFields['entidadFederativa'].status }
                  label="Entidad federativa"
                  name="entidadFederativa"
                  onChange={ onInputChange }
                  required={true}
                  requiredIndicator={true}
                  setFormFieldValidation={ setFormFieldValidation }
                  value={entidadFederativa}
                  values={['Aguascalientes', 'Baja California', 'Baja California Sur', 'Campeche', 'Chiapas', 'Chihuahua', 'Ciudad de México', 'Coahuila', 'Colima', 'Durango', 'Estado de México', 'Guanajuato', 'Guerrero', 'Hidalgo', 'Jalisco', 'Michoacán', 'Morelos', 'Nayarit', 'Nuevo León', 'Oaxaca', 'Puebla', 'Querétaro', 'Quintana Roo', 'San Luis Potosí', 'Sinaloa', 'Sonora', 'Tabasco', 'Tamaulipas', 'Tlaxcala', 'Veracruz', 'Yucatán', 'Zacatecas']}
                />
              </div>
              <div className="col-sm-12 col-md">
                <TextInput
                  formFieldStatus={ formFields['pais'].status }
                  label="País"
                  name="pais"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ pais }
                />
              </div>
              <div className="col-sm-12 col-md">
                <ZipCodeInput
                  formFieldStatus={ formFields['codigoPostal'].status }
                  label="Código postal"
                  name="codigoPostal"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ codigoPostal }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md">
                <EmailInput
                  formFieldStatus={ formFields['email'].status }
                  label="Correo electrónico"
                  name="email"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ email }
                />
              </div>
              <div className="col-sm-12 col-md">
                <TelInput
                  formFieldStatus={ formFields['telefono'].status }
                  label="Teléfono fijo"
                  name="telefono"
                  onChange={ onInputChange }
                  required={ false }
                  requiredIndicator={ false }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ telefono }
                />
              </div>
              <div className="col-sm-12 col-md">
                <TelInput
                  formFieldStatus={ formFields['celular'].status }
                  label="Teléfono móvil"
                  name="celular"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ celular }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className={styles.subHeader}>Horario</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
            <div className={styles.tarjeta}>
              <div className="row">
                <div className="col">
                  <div className={styles.tarjetaHeader}>Lunes</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <MultiFormHorario
                    formsStatus={ formFields['lunes'].status }
                    name="lunes"
                    onFormsChange={ onInputChange }
                    setFormValidation={ setFormFieldValidation }
                    value={lunes}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
            <div className={styles.tarjeta}>
              <div className="row">
                <div className="col">
                  <div className={styles.tarjetaHeader}>Martes</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <MultiFormHorario
                    formsStatus={ formFields['martes'].status }
                    name="martes"
                    onFormsChange={ onInputChange }
                    setFormValidation={ setFormFieldValidation }
                    value={martes}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
            <div className={styles.tarjeta}>
              <div className="row">
                <div className="col">
                  <div className={styles.tarjetaHeader}>Miercoles</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <MultiFormHorario
                    formsStatus={ formFields['miercoles'].status }
                    name="miercoles"
                    onFormsChange={ onInputChange }
                    setFormValidation={ setFormFieldValidation }
                    value={miercoles}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
            <div className={styles.tarjeta}>
              <div className="row">
                <div className="col">
                  <div className={styles.tarjetaHeader}>Jueves</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <MultiFormHorario
                    formsStatus={ formFields['jueves'].status }
                    name="jueves"
                    onFormsChange={ onInputChange }
                    setFormValidation={ setFormFieldValidation }
                    value={jueves}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
            <div className={styles.tarjeta}>
              <div className="row">
                <div className="col">
                  <div className={styles.tarjetaHeader}>Viernes</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <MultiFormHorario
                    formsStatus={ formFields['viernes'].status }
                    name="viernes"
                    onFormsChange={ onInputChange }
                    setFormValidation={ setFormFieldValidation }
                    value={viernes}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
            <div className={styles.tarjeta}>
              <div className="row">
                <div className="col">
                  <div className={styles.tarjetaHeader}>Sabado</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <MultiFormHorario
                    formsStatus={ formFields['sabado'].status }
                    name="sabado"
                    onFormsChange={ onInputChange }
                    setFormValidation={ setFormFieldValidation }
                    value={sabado}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
            <div className={styles.tarjeta}>
              <div className="row">
                <div className="col">
                  <div className={styles.tarjetaHeader}>Domingo</div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <MultiFormHorario
                    formsStatus={ formFields['domingo'].status }
                    name="domingo"
                    onFormsChange={ onInputChange }
                    setFormValidation={ setFormFieldValidation }
                    value={domingo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <div className={styles.subHeader}>Duración de citas (en minutos)</div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <MultiFormMotivosConsulta
              formsStatus={ formFields['motivos'].status }
              name="motivos"
              onFormsChange={ onInputChange }
              setFormValidation={ setFormFieldValidation }
              value={motivos}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <div className={styles.subHeader}>Días inactivos</div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {configuracion.inactivos.map(inactivo => <div key={inactivo._id} className="d-flex justify-content-start">
              <div className='mt-2'>{ inactivo.fecha }</div>
              <IconButton
                icon="X"
                iconColor={colors.DANGER}
                marginTop={false}
                onClick={() => handleEliminarInactivo(inactivo._id)}
                type="button"
              />
            </div>)}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="d-flex justify-content-start">
              <div>
                <DateInput
                  formFieldStatus={ formFieldsInactivo['fecha'].status }
                  label="Nueva fecha inactiva"
                  name="fecha"
                  onChange={ onInputChangeInactivo }
                  required={ false }
                  requiredIndicator={ false }
                  setFormFieldValidation={ setFormFieldValidationInactivo }
                  value={ fecha }
                />
              </div>
              <div className="ms-3">
                <label htmlFor=""></label>
                <PrimaryButton
                    disabled={fecha === '' || !isFormValidInactivo}
                    icon="Check"
                    marginTop={false}
                    onClick={handleSubmitInactivo}
                    text="Guardar"
                    type="button"
                    size={24}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md">
            <div className='d-flex justify-content-end'>
              <LightButton
                  disabled={isPending || formValidationStatus === 'checking'}
                  icon="X"
                  marginRight={true}
                  onClick={handleCancelarEditarConsultorio}
                  text="Cancelar"
                  type="button"
              />
              <PrimaryButton
                  disabled={isPending || formValidationStatus === 'checking'}
                  icon="Check"
                  text="Guardar"
                  type="submit"
              />
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

ConsultorioUpdate.propTypes = {
  consultorio: PropTypes.object.isRequired,
  configuracion: PropTypes.object.isRequired,
  handleCancelarEditarConsultorio: PropTypes.func.isRequired
}