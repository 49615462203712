import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { cancelarCita } from "../services/citas.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const useCitaCancelar = (id) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const cancelarCitaMutation = useMutation({
        mutationFn: cancelarCita,
        onSuccess: (cita, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['citas', id] });
            queryClient.invalidateQueries({ queryKey: ['historialCitas', id] });
            dispatch(successNotification('Cita cancelada'));
        },
        onError: (error, variables, context) => {
            variables.onComplete();
            dispatch(dangerNotification(error.message));
            // dispatch(dangerNotification(error.msg.message));
        }
    });

    return {
        cancelarCitaMutation,
    }
}