import { useEffect } from "react";
import PropTypes from 'prop-types';

import { useForm, useFormValidation } from "../../../../hooks";

import { AlergenoInput, IconButton, TextInput } from "../../..";

import { colors } from "../../../../types/types";

import styles from '../MultiFormAlergenos.module.css';

export const AlergenoForm = ({ element, formStatus='checked', onFormChange, prueba, removeElement }) => {

  const { alergeno, resultados, onInputChange } = useForm({ alergeno: element.alergeno, resultados: element.resultados });

  const { isFormValid, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'alergeno', default: true}, {field: 'resultados', default: true}]);

  useEffect(() => {
    if(onFormChange) onFormChange({ id: element.id, alergeno, resultados, validation: isFormValid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alergeno, resultados, isFormValid])

  useEffect(() => {
    if(formStatus === 'checking') startFormValidation();
  }, [formStatus, startFormValidation]);

  useEffect(() => {
    if(alergeno !== ''){
      startFormValidation();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="row">
        <div className="col-md-12 col-lg-6">
            <AlergenoInput
              formFieldStatus={ formFields['alergeno'].status }
              isClearable={true}
              label="Alergeno"
              name="alergeno"
              onChange={ onInputChange }
              prueba={prueba}
              required={false}
              requiredIndicator={false}
              setFormFieldValidation={ setFormFieldValidation }
              value={alergeno}
            />
        </div>
        <div className="col-md-12 col-lg-5">
            <TextInput
              formFieldStatus={ formFields['resultados'].status }
              label="Resultados"
              name="resultados"
              onChange={ onInputChange }
              required={ false }
              requiredIndicator={ false }
              setFormFieldValidation={ setFormFieldValidation }
              value={ resultados }
            />
        </div>
        <div className={"col-md-12 col-lg-1"}>
            <div className={styles.closeBtnContainer}>
                <div>
                    <label htmlFor="">&nbsp;</label>
                    <IconButton
                        icon="Trash3"
                        iconColor={colors.DANGER}
                        marginTop={false}
                        onClick={() => removeElement(element.id)}
                        type="button"
                        size={20}
                    />
                </div>
            </div>
        </div>
    </div>
  )
}

AlergenoForm.propTypes = {
  element: PropTypes.any.isRequired,
  formStatus: PropTypes.oneOf(['checked', 'checking']),
  onFormChange: PropTypes.func,
  prueba: PropTypes.string.isRequired,
  removeElement: PropTypes.func.isRequired,
}