import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query"

import { createPacienteConsulta } from "../services/consulta.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacienteConsultaCrear = (id) => {

    const dispatch = useDispatch();

    // const queryClient = useQueryClient();

    const createConsultaMutation = useMutation({
        mutationFn: createPacienteConsulta,
        onSuccess: ({ inactivoFlag, horarioFlag, empalmadaFlag, consulta }, variables, context) => {
            if(inactivoFlag){
                variables.onInactivo();
                dispatch(dangerNotification(consulta));
            }else if(horarioFlag){
                variables.onHorario();
                dispatch(dangerNotification(consulta));
            }else if(empalmadaFlag){
                variables.onEmpalmada();
                dispatch(dangerNotification(consulta));
            }else{
                // queryClient.invalidateQueries({ queryKey: ['paciente/inmunoterapia/proxima', id] });
                variables.success();
                dispatch(successNotification('Consulta registrada'));
            }
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        createConsultaMutation,
    }
}