import { useState } from 'react';
import PropTypes from 'prop-types';

import { useMotivo } from './hooks/useMotivo';
import { useCitaNueva } from './hooks/useCitaNueva';
import { useCitaEditar } from './hooks/useCitaEditar';
import { useCitaPosponer } from './hooks/useCitaPosponer';

import { useForm, useFormValidation } from '../../../hooks';

import { PrimaryButton, LightButton, DateInput, TimeInput, NumberInput, TextAreaInput, ReactSelectSingleKeyInput } from '../../../components';

import { eliminarUndefined } from '../../../helpers/eliminarUndefined';

import styles from '../Modals.module.css';

export const CitaPacienteModal = ({ paciente: pacienteAux, cita = null, hide, posponer = false }) => {

    const [inactivoFlag, setInactivoFlag] = useState(false);
    const [horarioFlag, setHorarioFlag] = useState(false);
    const [empalmadaFlag, setEmpalmadaFlag] = useState(false);

    const activeInactivoFlag = () => {
        setInactivoFlag(true);
    }

    const activeHorarioFlag = () => {
        setHorarioFlag(true);
    }

    const activeEmpalmadaFlag = () => {
        setEmpalmadaFlag(true);
    }

    const { createCitaMutation: { mutate: createCita } } = useCitaNueva(pacienteAux);
    const { updateCitaMutation: { mutate: updateCita } } = useCitaEditar(pacienteAux);
    const { posponerCitaMutation: { mutate: posponerCita } } = useCitaPosponer(pacienteAux);

    const { motivoQuery: { isLoading, isError, data: motivos } } = useMotivo();

    const { paciente, fecha, hora, motivo, comentario, onInputChange, onResetForm } = useForm({ paciente: pacienteAux, fecha: (cita !== null) ? cita.diaSinFormato : '', hora: (cita !== null) ? cita.horaSinFormato : '', motivo: (cita !== null) ? cita.motivo._id : '', comentario: (cita !== null) ? cita.comentario : '' });

    const { duracion, onInputChange: onInputChangeSecond, onResetForm: onResetFormSecond } = useForm({ duracion: (cita !== null) ? cita.duracion : 0 });

    const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation, onResetValidation } = useFormValidation([{field: 'fecha', default: (cita !== undefined) ? true : false}, {field: 'hora', default: (cita !== undefined) ? true : false}, {field: 'motivo', default: (cita !== undefined) ? true : false}, {field: 'comentario', default: true}]);

    const { isFormValid: isFormValidSecond, formValidationStatus: formValidationStatusSecond, formFields: formFieldsSecond, startFormValidation: startFormValidationSecond, setFormFieldValidation: setFormFieldValidationSecond, onResetValidation: onResetValidationSecond } = useFormValidation([{field: 'duracion', default: true}]);

    const handleMotivoChange = (e) => {
        setInactivoFlag(false);
        setHorarioFlag(false);
        setEmpalmadaFlag(false);
        onInputChange(e);

        onInputChangeSecond({ target: { name: 'duracion', value: motivos.find(motivo => String(motivo._id) === String(e.target.value)).duracion } });
    }

    const handleInputChange = (e) => {
        setInactivoFlag(false);
        setHorarioFlag(false);
        setEmpalmadaFlag(false);
        onInputChange(e);
    }

    const handleInputChangeSecond = (e) => {
        setInactivoFlag(false);
        setHorarioFlag(false);
        setEmpalmadaFlag(false);
        onInputChangeSecond(e);
    }

    const handleSuccessSubmit = () => {
        onResetForm();
        onResetValidation();
        onResetFormSecond();
        onResetValidationSecond();
    }

    const onSubmit = async ( event ) => {
        event.preventDefault();

        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid && formValidationStatusSecond === 'checked' && isFormValidSecond != null && isFormValidSecond){
            if(cita === null){
                createCita({ data: eliminarUndefined({ paciente, fecha, hora, motivo, duracion, comentario, inactivoConfirmacion: inactivoFlag, horarioConfirmacion: horarioFlag, empalmadaConfirmacion: empalmadaFlag }, { comentario: undefined }), onSuccess: handleSuccessSubmit, onComplete: hide, onInactivo: activeInactivoFlag, onHorario: activeHorarioFlag, onEmpalmada: activeEmpalmadaFlag});
            }else if(!posponer){
                updateCita({ id: cita._id, data: { paciente, fecha, hora, motivo, duracion, comentario, inactivoConfirmacion: inactivoFlag, horarioConfirmacion: horarioFlag, empalmadaConfirmacion: empalmadaFlag }, onSuccess: handleSuccessSubmit, onComplete: hide, onInactivo: activeInactivoFlag, onHorario: activeHorarioFlag, onEmpalmada: activeEmpalmadaFlag});
            }else{
                posponerCita({ id: cita._id, data: { paciente, fecha, hora, inactivoConfirmacion: inactivoFlag, horarioConfirmacion: horarioFlag, empalmadaConfirmacion: empalmadaFlag }, onSuccess: handleSuccessSubmit, onComplete: hide, onInactivo: activeInactivoFlag, onHorario: activeHorarioFlag, onEmpalmada: activeEmpalmadaFlag});
            }
        }else{
            startFormValidation();
            startFormValidationSecond();
        }

    }

    return (
        <>
        {(isLoading)
            ? <>Cargando...</>
            : <>
                {(isError)
                    ? <>Error</>
                    : <>
                        <div>
                            <form onSubmit={ onSubmit }>
                                <div className="row mb-3">
                                    <div className="col">
                                        <div className={styles.modalHeader}>{(cita === null) ? "Cita nueva" : ((!posponer) ? "Editar cita" : "Posponer cita")}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <DateInput
                                            formFieldStatus={ formFields['fecha'].status }
                                            label="Fecha"
                                            name="fecha"
                                            onChange={ handleInputChange }
                                            required={ true }
                                            requiredIndicator={ true }
                                            setFormFieldValidation={ setFormFieldValidation }
                                            value={ fecha }
                                        />
                                    </div>
                                    <div className="col-6">
                                        <TimeInput
                                            formFieldStatus={ formFields['hora'].status }
                                            label="Hora"
                                            name="hora"
                                            onChange={ handleInputChange }
                                            required={ true }
                                            requiredIndicator={ true }
                                            setFormFieldValidation={ setFormFieldValidation }
                                            value={ hora }
                                        />
                                    </div>
                                </div>
                                {(cita === null || !posponer) && <>
                                <div className="row">
                                    <div className="col-6">
                                        <ReactSelectSingleKeyInput
                                            formFieldStatus={ formFields['motivo'].status }
                                            label="Motivo"
                                            name="motivo"
                                            onChange={ handleMotivoChange }
                                            required={true}
                                            requiredIndicator={true}
                                            setFormFieldValidation={ setFormFieldValidation }
                                            value={motivo}
                                            values={motivos.map(motivo => ({ value: motivo._id, label: motivo.nombre }))}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <NumberInput
                                            formFieldStatus={ formFieldsSecond['duracion'].status }
                                            label="Duración (min)"
                                            name="duracion"
                                            onChange={ handleInputChangeSecond }
                                            required={ true }
                                            requiredIndicator={ true }
                                            setFormFieldValidation={ setFormFieldValidationSecond }
                                            value={ duracion }
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <TextAreaInput
                                            formFieldStatus={ formFields['comentario'].status }
                                            label="Comentario"
                                            name="comentario"
                                            onChange={ handleInputChange }
                                            required={ false }
                                            requiredIndicator={ false }
                                            setFormFieldValidation={ setFormFieldValidation }
                                            value={ comentario }
                                        />
                                    </div>
                                </div>
                                </>}
                                <div className="row">
                                    <div className="col">
                                        {(inactivoFlag) && <p className={styles.helperErrorText}>La fecha seleccionada esta marcada como día inactivo en la configuración del consultorio. ¿Deseas continuar igualmente?</p>}
                                        {(horarioFlag) && <p className={styles.helperErrorText}>El periodo de tiempo seleccionado esta fuera del horario configurado para el consultorio. ¿Deseas continuar igualmente?</p>}
                                        {(empalmadaFlag) && <p className={styles.helperErrorText}>El periodo de tiempo seleccionado se empalma con otra cita previamente agendada. ¿Deseas continuar igualmente?</p>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6">
                                        <LightButton
                                            block={true}
                                            icon="X"
                                            marginTop={false}
                                            onClick={ hide }
                                            text="Cancelar"
                                            type="button"
                                        />
                                    </div>
                                    <div className='col-xs-12 col-sm-12 col-md-6'>
                                        <PrimaryButton
                                            block={true}
                                            disabled={formValidationStatus === 'checking'}
                                            icon="Check"
                                            marginTop={false}
                                            text={(inactivoFlag || horarioFlag || empalmadaFlag) ? "Confirmar" : ((cita === null) ? "Crear" : ((!posponer) ? "Editar" : "Posponer"))}
                                            type="submit"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
                }
            </>
        }
        </>
    )
}

CitaPacienteModal.propTypes = {
  paciente: PropTypes.string.isRequired,
  cita: PropTypes.object,
  hide: PropTypes.func.isRequired,
  posponer: PropTypes.bool,
}