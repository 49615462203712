import { inmunetApi } from '../../../api';

export const getTipo = async() => {

    try {

        const { data } = await inmunetApi.post(`/inmunoterapia/tipos`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.tipos;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const getFase = async(tipo) => {

    try {

        if (tipo === '') return [];

        let formData = new FormData();
        formData.append(`tipo`, tipo);

        const { data } = await inmunetApi.post(`/inmunoterapia/fases`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.fases;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const getSitio = async(tipo) => {

    try {

        if (tipo === '') return [];

        let formData = new FormData();
        formData.append(`tipo`, tipo);

        const { data } = await inmunetApi.post(`/inmunoterapia/sitios`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return {
            sitiosFlag: data.sitiosFlag,
            sitios: data.sitios
        };

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const getLado = async(tipo) => {

    try {

        if (tipo === '') return { ladoFlag: true, lados: ['Derecho', 'Izquierdo'] };

        let formData = new FormData();
        formData.append(`tipo`, tipo);

        const { data } = await inmunetApi.post(`/inmunoterapia/lado`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return {
            ladoFlag: data.ladoFlag,
            lados: data.lados
        };

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const getReaccion = async(tipo) => {

    try {

        if (tipo === '') return true;

        let formData = new FormData();
        formData.append(`tipo`, tipo);

        const { data } = await inmunetApi.post(`/inmunoterapia/reaccion`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.reaccionFlag;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const getFrasco = async(tipo, fase) => {

    try {

        if (tipo === '' || fase === '') return [];

        let formData = new FormData();
        formData.append(`tipo`, tipo);
        formData.append(`fase`, fase);

        const { data } = await inmunetApi.post(`/inmunoterapia/frascos`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.frascos;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const getFrascoData = async(id, tipo, fase, frasco, fecha, lado) => {

    try {

        if (id === '' || tipo === '' || fase === '' || frasco === '' || fecha === '') return {};

        let formData = new FormData();
        formData.append(`tipo`, tipo);
        formData.append(`fase`, fase);
        formData.append(`frasco`, frasco);
        formData.append(`fecha`, fecha);
        formData.append(`lado`, lado);

        const { data } = await inmunetApi.post(`/inmunoterapia/frasco-data/${id}`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return {
            frasco: data.frasco,
            aplicaciones: data.aplicaciones,
            alergenos: data.alergenos,
            alergenosActivos: data.alergenosActivos
        };

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const getPacienteInmunoterapiaProxima = async(id) => {

    try {

        const { data } = await inmunetApi.get(`/inmunoterapia/proximas/${id}`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.inmunoterapias;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const getPacienteInmunoterapiaPrevia = async(id) => {

    try {

        const { data } = await inmunetApi.get(`/inmunoterapia/pasadas/${id}`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.inmunoterapias;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const createPacienteInmunoterapia = async({ data: inmunoterapia, id }) => {

    try {

        const { data } = await inmunetApi.post(`/inmunoterapia/${id}`, inmunoterapia);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al crear inmunoterapia';

        return data.inmunoterapia;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error('Error al crear inmunoterapia');
        }

    }

};

export const createPacienteInmunoterapiaAplicacion = async({ data: notis, id }) => {

    try {

        const { data } = await inmunetApi.post(`/inmunoterapia/aplicacion/${id}`, notis);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al crear aplicación de inmunoterapia';

        return {
            inmunoterapia: data.inmunoterapia,
            caducidad: false,
            limite: false,
        };

    } catch (error) {

        if (error.response.data.caducidad) {
            return {
                caducidad: true,
                limite: false,
                inmunoterapia: {},
            };
        } else if (error.response.data.limite) {
            return {
                caducidad: false,
                limite: true,
                inmunoterapia: {},
            };
        } else {
            if (error.response.data.msg) {
                throw new Error(error.response.data.msg);
            } else {
                throw new Error('Error al crear aplicación de inmunoterapia');
            }
        }

    }

};

export const updatePacienteInmunoterapiaAplicacion = async({ data: formData, id, aplicacion }) => {

    try {

        const { data } = await inmunetApi.put(`/inmunoterapia/${id}/${aplicacion}`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al editar aplicación de inmunoterapia';

        return {
            inmunoterapia: data.inmunoterapia,
            caducidad: false,
            limite: false,
        };

    } catch (error) {

        if (error.response.data.caducidad) {
            return {
                caducidad: true,
                limite: false,
                inmunoterapia: {},
            };
        } else if (error.response.data.limite) {
            return {
                caducidad: false,
                limite: true,
                inmunoterapia: {},
            };
        } else {
            if (error.response.data.msg) {
                throw new Error(error.response.data.msg);
            } else {
                throw new Error('Error al editar aplicación de inmunoterapia');
            }
        }

    }

};

export const applyPacienteInmunoterapiaAplicacion = async({ data: formData, id, aplicacion }) => {

    try {

        const { data } = await inmunetApi.put(`/inmunoterapia/toggle-aplicar/${id}/${aplicacion}`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al aplicar inmunoterapia';

        return {
            inmunoterapia: data.inmunoterapia,
            notificacion: false,
            msg: '',
            caducidad: false,
            limite: false,
        };

    } catch (error) {

        if (error.response.data.notificacion) {
            return {
                notificacion: true,
                msg: error.response.data.msg,
                caducidad: false,
                limite: false,
                inmunoterapia: {},
            };
        } else if (error.response.data.caducidad) {
            return {
                notificacion: false,
                msg: '',
                caducidad: true,
                limite: false,
                inmunoterapia: {},
            };
        } else if (error.response.data.limite) {
            return {
                notificacion: false,
                msg: '',
                caducidad: false,
                limite: true,
                inmunoterapia: {},
            };
        } else {
            if (error.response.data.msg) {
                throw new Error(error.response.data.msg);
            } else {
                throw new Error('Error al aplicar inmunoterapia');
            }
        }

    }

};

export const delayPacienteInmunoterapiaAplicacion = async({ data: formData, id, aplicacion }) => {

    try {

        const { data } = await inmunetApi.put(`/inmunoterapia/reprogramar/${id}/${aplicacion}`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al reprogramar inmunoterapia';

        return {
            inmunoterapia: data.inmunoterapia,
            notificacion: false,
            msg: '',
            caducidad: false,
            limite: false,
        };

    } catch (error) {

        if (error.response.data.notificacion) {
            return {
                notificacion: true,
                msg: error.response.data.msg,
                caducidad: false,
                limite: false,
                inmunoterapia: {},
            };
        } else if (error.response.data.caducidad) {
            return {
                notificacion: false,
                msg: '',
                caducidad: true,
                limite: false,
                inmunoterapia: {},
            };
        } else if (error.response.data.limite) {
            return {
                notificacion: false,
                msg: '',
                caducidad: false,
                limite: true,
                inmunoterapia: {},
            };
        } else {
            if (error.response.data.msg) {
                throw new Error(error.response.data.msg);
            } else {
                throw new Error('Error al reprogramar inmunoterapia');
            }
        }

    }

};

export const deletePacienteInmunoterapiaAplicacion = async({ data: notis, id, aplicacion }) => {

    try {

        const { data } = await inmunetApi.post(`/inmunoterapia/aplicacion/${id}/${aplicacion}`, notis);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al eliminar aplicación de inmunoterapia';

        return {
            inmunoterapia: data.inmunoterapia,
            caducidad: false,
            limite: false,
        };

    } catch (error) {

        if (error.response.data.caducidad) {
            return {
                caducidad: true,
                limite: false,
                inmunoterapia: {},
            };
        } else if (error.response.data.limite) {
            return {
                caducidad: false,
                limite: true,
                inmunoterapia: {},
            };
        } else {
            if (error.response.data.msg) {
                throw new Error(error.response.data.msg);
            } else {
                throw new Error('Error al eliminar aplicación de inmunoterapia');
            }
        }

    }

};

export const finishPacienteInmunoterapia = async({ id }) => {

    try {

        const { data } = await inmunetApi.put(`/inmunoterapia/finalizar/${ id }`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al finalizar inmunoterapia';

        return {
            inmunoterapia: data.inmunoterapia,
            nueva: data.nueva,
            tipos: data.tipos,
            fases: data.fases,
            sitiosFlag: data.sitiosFlag,
            sitios: data.sitios,
            ladoFlag: data.ladoFlag,
            lados: data.lados,
            reaccionFlag: data.reaccionFlag,
            frascos: data.frascos
        };

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error('Error al finalizar inmunoterapia');
        }

    }

};

export const deletePacienteInmunoterapia = async(id) => {

    try {

        const { data } = await inmunetApi.delete(`/inmunoterapia/eliminar/${ id }`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al eliminar inmunoterapia';

        return id;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error('Error al eliminar inmunoterapia');
        }

    }

};