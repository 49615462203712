import React, { useState } from 'react'

import { MovilTabSelector } from '../../components';
import { DatosGenerales } from './components/DatosGenerales';
import { Consulta } from './components/Consulta';
import { PruebasAlergia } from './components/PruebasAlergia';
import { Subcutanea } from './components/Subcutanea';
import { Sublingual } from './components/Sublingual';
import { Alergoide } from './components/Alergoide';

import styles from './ConfiguracionExpedienteClinico.module.css';

export const ConfiguracionExpedienteClinico = () => {

    const [tab, setTab] = useState(1);

    return (
        <>
            <div className={styles.header}>Expediente Clínico</div>
            <div  className={styles.tabsContainer}>
                <div className={"row " + styles.web}>
                    <div className="col p-0 text-center">
                        <div className={ (tab === 1) ? styles.tabActive : styles.tab } onClick={() => setTab(1)}>DATOS GENERALES</div>
                    </div>
                    <div className="col p-0 text-center">
                        <div className={ (tab === 2) ? styles.tabActive : styles.tab } onClick={() => setTab(2)}>CONSULTA</div>
                    </div>
                    <div className="col p-0 text-center">
                        <div className={ (tab === 3) ? styles.tabActive : styles.tab } onClick={() => setTab(3)}>PRUEBAS DE ALERGIA</div>
                    </div>
                    <div className="col p-0 text-center">
                        <div className={ (tab === 4) ? styles.tabActive : styles.tab } onClick={() => setTab(4)}>SUBCUTÁNEA</div>
                    </div>
                    <div className="col p-0 text-center">
                        <div className={ (tab === 5) ? styles.tabActive : styles.tab } onClick={() => setTab(5)}>SUBLINGUAL</div>
                    </div>
                    <div className="col p-0 text-center">
                        <div className={ (tab === 6) ? styles.tabActive : styles.tab } onClick={() => setTab(6)}>ALERGOIDE</div>
                    </div>
                </div>
                <div className={"row p-0 " + styles.movil}>
                    <div className="col p-0">
                        <MovilTabSelector
                            onChange={ setTab }
                            value={String(tab)}
                            values={[{ value: '1', label: 'DATOS GENERALES' }, { value: '2', label: 'CONSULTA' }, { value: '3', label: 'PRUEBAS DE ALERGIA' }, { value: '4', label: 'SUBCUTÁNEA' }, { value: '5', label: 'SUBLINGUAL' }, { value: '6', label: 'ALERGOIDE' }]}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.tabsContentContainer}>
                { (tab === 1) && <DatosGenerales /> }
                { (tab === 2) && <Consulta /> }
                { (tab === 3) && <PruebasAlergia /> }
                { (tab === 4) && <Subcutanea /> }
                { (tab === 5) && <Sublingual /> }
                { (tab === 6) && <Alergoide /> }
            </div>
        </>
    )
}
