import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useForm, useFormValidation } from '../../hooks';
import { startSignup } from '../../redux/slices/auth';

import { DateInput, EmailInput, Link, PasswordConfirmationInput, PasswordInput, PrimaryButton, ReactSelectSingleInput, TelInput, TextInput, ZipCodeInput } from '../../components';

import { eliminarUndefined } from '../../helpers';

import styles from './Signup.module.css';

export const Signup = () => {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { nombre, apellidoPaterno, apellidoMaterno, fechaNacimiento, genero, email, celular, password, confirmation, especialidad, direccionConsultorio, infoConsultorio, coloniaConsultorio, paisConsultorio, entidadFederativaConsultorio, municipioConsultorio, codigoPostalConsultorio, telefonoConsultorio, celularConsultorio, emailConsultorio, nombreConsultorio, formState, onInputChange } = useForm({ nombre: '', apellidoPaterno: '', apellidoMaterno: '', fechaNacimiento: '', genero: '', email: '', celular: '', password: '', confirmation: '', especialidad: '', direccionConsultorio: '', infoConsultorio: '', coloniaConsultorio: '', paisConsultorio: '', entidadFederativaConsultorio: '', municipioConsultorio: '', codigoPostalConsultorio: '', telefonoConsultorio: '', celularConsultorio: '', emailConsultorio: '', nombreConsultorio: '' });

  const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'nombre', default: false}, {field: 'apellidoPaterno', default: false}, {field: 'apellidoMaterno', default: true}, {field: 'fechaNacimiento', default: false}, {field: 'genero', default: false}, {field: 'email', default: false}, {field: 'celular', default: false}, {field: 'password', default: false}, {field: 'confirmation', default: false}, {field: 'especialidad', default: false}, {field: 'direccionConsultorio', default: false}, {field: 'infoConsultorio', default: true}, {field: 'coloniaConsultorio', default: false}, {field: 'paisConsultorio', default: false}, {field: 'entidadFederativaConsultorio', default: false}, {field: 'municipioConsultorio', default: false}, {field: 'codigoPostalConsultorio', default: false}, {field: 'telefonoConsultorio', default: true}, {field: 'celularConsultorio', default: false}, {field: 'emailConsultorio', default: false}, {field: 'nombreConsultorio', default: false}]);

  const onSubmit = async ( event ) => {
    event.preventDefault();
    
    if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
      let res = await dispatch(startSignup(eliminarUndefined(formState, { apellidoMaterno: undefined, infoConsultorio: undefined, telefonoConsultorio: undefined })));
      if(res){
        navigate("/");
      }
    }else{
      startFormValidation();
    }

  }

  const handleLoginRedirection = () => {
    navigate("/");
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <form onSubmit={ onSubmit }>
            <div className="row">
              <div className="col">
                <div className={styles.formHeader}>Crear cuenta</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg">
                <TextInput
                  formFieldStatus={ formFields['nombre'].status }
                  label="Nombre(s)"
                  name="nombre"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ nombre }
                />
              </div>
              <div className='col-md-12 col-lg'>
                <TextInput
                  formFieldStatus={ formFields['apellidoPaterno'].status }
                  label="Apellido paterno"
                  name="apellidoPaterno"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ apellidoPaterno }
                />
              </div>
              <div className='col-md-12 col-lg'>
                <TextInput
                  formFieldStatus={ formFields['apellidoMaterno'].status }
                  label="Apellido materno"
                  name="apellidoMaterno"
                  onChange={ onInputChange }
                  required={ false }
                  requiredIndicator={ false }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ apellidoMaterno }
                />
              </div>
              <div className='col-md-12 col-lg'>
                <DateInput
                  formFieldStatus={ formFields['fechaNacimiento'].status }
                  label="Fecha de nacimiento"
                  name="fechaNacimiento"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ fechaNacimiento }
                />
              </div>
              <div className='col-md-12 col-lg'>
                <ReactSelectSingleInput
                  formFieldStatus={ formFields['genero'].status }
                  label="Género"
                  name="genero"
                  onChange={ onInputChange }
                  required={true}
                  requiredIndicator={true}
                  setFormFieldValidation={ setFormFieldValidation }
                  value={genero}
                  values={['Masculino', 'Femenino', 'Otro', 'Prefiero no decirlo']}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-lg'>
                <TelInput
                  formFieldStatus={ formFields['celular'].status }
                  label="Celular"
                  name="celular"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ celular }
                />
              </div>
              <div className='col-md-12 col-lg'>
                <ReactSelectSingleInput
                  formFieldStatus={ formFields['especialidad'].status }
                  label="Especialidad"
                  name="especialidad"
                  onChange={ onInputChange }
                  required={true}
                  requiredIndicator={true}
                  setFormFieldValidation={ setFormFieldValidation }
                  value={especialidad}
                  values={['Alergia e Inmunología Clínica', 'Alergología pediátrica']}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                <EmailInput
                  formFieldStatus={ formFields['email'].status }
                  label="Correo electrónico"
                  name="email"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ email }
                />
              </div>
              <div className='col-md-12 col-lg-3 col-xl-3 col-xxl-3'>
                <PasswordInput
                  formFieldStatus={ formFields['password'].status }
                  label="Contraseña"
                  name="password"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ password }
                />
              </div>
              <div className='col-md-12 col-lg-3 col-xl-3 col-xxl-3'>
                <PasswordConfirmationInput
                  formFieldStatus={ formFields['confirmation'].status }
                  label="Confirmar contraseña"
                  name="confirmation"
                  onChange={ onInputChange }
                  password={ password }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ confirmation }
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className={styles.formHeader}>Consultorio</div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-lg'>
                <TextInput
                  formFieldStatus={ formFields['direccionConsultorio'].status }
                  label="Dirección"
                  name="direccionConsultorio"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ direccionConsultorio }
                />
              </div>
              <div className='col-md-12 col-lg'>
                <TextInput
                  formFieldStatus={ formFields['infoConsultorio'].status }
                  label="Info. adicional (Piso, # Consultorio, etc.)"
                  name="infoConsultorio"
                  onChange={ onInputChange }
                  required={ false }
                  requiredIndicator={ false }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ infoConsultorio }
                />
              </div>
              <div className='col-md-12 col-lg'>
                <TextInput
                  formFieldStatus={ formFields['coloniaConsultorio'].status }
                  label="Colonia"
                  name="coloniaConsultorio"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ coloniaConsultorio }
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-lg'>
                <TextInput
                  formFieldStatus={ formFields['paisConsultorio'].status }
                  label="País"
                  name="paisConsultorio"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ paisConsultorio }
                />
              </div>
              <div className='col-md-12 col-lg'>
                <ReactSelectSingleInput
                  formFieldStatus={ formFields['entidadFederativaConsultorio'].status }
                  label="Estado"
                  name="entidadFederativaConsultorio"
                  onChange={ onInputChange }
                  required={true}
                  requiredIndicator={true}
                  setFormFieldValidation={ setFormFieldValidation }
                  value={entidadFederativaConsultorio}
                  values={['Aguascalientes', 'Baja California', 'Baja California Sur', 'Campeche', 'Chiapas', 'Chihuahua', 'Ciudad de México', 'Coahuila', 'Colima', 'Durango', 'Estado de México', 'Guanajuato', 'Guerrero', 'Hidalgo', 'Jalisco', 'Michoacán', 'Morelos', 'Nayarit', 'Nuevo León', 'Oaxaca', 'Puebla', 'Querétaro', 'Quintana Roo', 'San Luis Potosí', 'Sinaloa', 'Sonora', 'Tabasco', 'Tamaulipas', 'Tlaxcala', 'Veracruz', 'Yucatán', 'Zacatecas']}
                />
              </div>
              <div className='col-md-12 col-lg'>
                <TextInput
                  formFieldStatus={ formFields['municipioConsultorio'].status }
                  label="Municipio"
                  name="municipioConsultorio"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ municipioConsultorio }
                />
              </div>
              <div className='col-md-12 col-lg'>
                <ZipCodeInput
                  formFieldStatus={ formFields['codigoPostalConsultorio'].status }
                  label="Código postal"
                  name="codigoPostalConsultorio"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ codigoPostalConsultorio }
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-lg'>
                <TelInput
                  formFieldStatus={ formFields['telefonoConsultorio'].status }
                  label="Teléfono fijo"
                  name="telefonoConsultorio"
                  onChange={ onInputChange }
                  required={ false }
                  requiredIndicator={ false }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ telefonoConsultorio }
                />
              </div>
              <div className='col-md-12 col-lg'>
                <TelInput
                  formFieldStatus={ formFields['celularConsultorio'].status }
                  label="Teléfono móvil"
                  name="celularConsultorio"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ celularConsultorio }
                />
              </div>
              <div className='col-md-12 col-lg'>
                <EmailInput
                  formFieldStatus={ formFields['emailConsultorio'].status }
                  label="Correo electrónico"
                  name="emailConsultorio"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ emailConsultorio }
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-lg'>
                <TextInput
                  formFieldStatus={ formFields['nombreConsultorio'].status }
                  label="Nombre del consultorio"
                  name="nombreConsultorio"
                  onChange={ onInputChange }
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ nombreConsultorio }
                />
              </div>
              <div className='col-md-12 col-lg'></div>
            </div>
            <div className='row'>
              <div className='col'>
              <span className={styles.formBoldText}>*Al crear mi cuenta acepto las</span> <Link onClick={() => {}} text="Condiciones de uso" /> <span className={styles.formBoldText}>y</span> <Link onClick={() => {}} text="Politica de privacidad de datos" /><span className={styles.formBoldText}>.</span>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <PrimaryButton
                  inverse={true}
                  onClick={handleLoginRedirection}
                  text="Regresar"
                  type="button"
                />
              </div>
              <div className='col'>
                <div className='float-end'>
                  <PrimaryButton
                    disabled={formValidationStatus === 'checking'}
                    text="Crear cuenta"
                    type="submit"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
