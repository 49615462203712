import PropTypes from 'prop-types';

import { colors } from '../../../types/types';

import { useConsultorios } from '../hooks/useConsultorios';
import { useForm, useFormValidation } from '../../../hooks';

import { CheckboxInput, ColorInput, EmailInput, ImgCircledInput, LightButton, PrimaryButton, ReactSelectSingleInput, TelInput, TextInput, ZipCodeInput } from '../../../components';

import { eliminarUndefined } from '../../../helpers';

import styles from '../Consultorios.module.css';

export const ConsultorioCreate = ({ handleCancelarCrearConsultorio }) => {

    const { createConsultorioMutation: { mutate: createConsultorio, isPending } } = useConsultorios();

    const { nombre, direccion, info, colonia, municipio, entidadFederativa, pais, codigoPostal, email, telefono, celular, principal, color, img, activo, onInputChange } = useForm({ nombre: '', direccion: '', info: '', colonia: '', municipio: '', entidadFederativa: '', pais: '', codigoPostal: '', email: '', telefono: '', celular: '', principal: false, color: colors.PRIMARY, img: null, activo: true });

    const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'nombre', default: false}, {field: 'direccion', default: false}, {field: 'info', default: true}, {field: 'colonia', default: false}, {field: 'municipio', default: false}, {field: 'entidadFederativa', default: false}, {field: 'pais', default: false}, {field: 'codigoPostal', default: false}, {field: 'email', default: false}, {field: 'telefono', default: true}, {field: 'celular', default: false}, {field: 'color', default: true}, {field: 'img', default: true}]);

    const onSubmit = async ( event ) => {
        event.preventDefault();
        
        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
            createConsultorio({ data: eliminarUndefined({ nombre, direccion, info, colonia, municipio, entidadFederativa, pais, codigoPostal, email, telefono, celular, principal, color, img, activo }, { info: undefined, telefono: undefined } , ['img']), callback: handleCancelarCrearConsultorio });
        }else{
            startFormValidation();
        }
    }

    return (
        <div className={styles.panel}>
            <form onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-12 col-lg-2">
                        <ImgCircledInput
                            formFieldStatus={ formFields['img'].status }
                            imgText="Logo"
                            label="Logotipo"
                            name="img"
                            onChange={ onInputChange }
                            required={ false }
                            requiredIndicator={ false }
                            setFormFieldValidation={ setFormFieldValidation }
                            value={img}
                        />
                    </div>
                    <div className="col-md-12 col-lg-10">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <TextInput
                                    formFieldStatus={ formFields['nombre'].status }
                                    label="Nombre del consultorio"
                                    name="nombre"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ true }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ nombre }
                                />
                            </div>
                            <div className="col-sm-12 col-md d-flex align-items-center">
                                <CheckboxInput
                                    label="Consultorio principal"
                                    name="principal"
                                    onChange={ onInputChange }
                                    required={false}
                                    checked={ (principal === 'true' || principal === true) ? true : false }
                                />
                            </div>
                            <div className="col-sm-12 col-md d-flex align-items-center">
                                <CheckboxInput
                                    label="Consultorio activo"
                                    name="activo"
                                    onChange={ onInputChange }
                                    required={false}
                                    checked={ (activo === 'true' || activo === true) ? true : false }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <ColorInput
                                    formFieldStatus={ formFields['color'].status }
                                    label="Color de tema"
                                    name="color"
                                    onChange={ onInputChange }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ color }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md">
                                <TextInput
                                    formFieldStatus={ formFields['direccion'].status }
                                    label="Dirección"
                                    name="direccion"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ true }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ direccion }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <TextInput
                                    formFieldStatus={ formFields['info'].status }
                                    label="Info. adicional (Piso, # Consultorio, etc.)"
                                    name="info"
                                    onChange={ onInputChange }
                                    required={ false }
                                    requiredIndicator={ false }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ info }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <TextInput
                                    formFieldStatus={ formFields['colonia'].status }
                                    label="Colonia"
                                    name="colonia"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ true }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ colonia }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <TextInput
                                    formFieldStatus={ formFields['municipio'].status }
                                    label="Municipio"
                                    name="municipio"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ true }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ municipio }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md">
                                <ReactSelectSingleInput
                                    formFieldStatus={ formFields['entidadFederativa'].status }
                                    label="Entidad federativa"
                                    name="entidadFederativa"
                                    onChange={ onInputChange }
                                    required={true}
                                    requiredIndicator={true}
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={entidadFederativa}
                                    values={['Aguascalientes', 'Baja California', 'Baja California Sur', 'Campeche', 'Chiapas', 'Chihuahua', 'Ciudad de México', 'Coahuila', 'Colima', 'Durango', 'Estado de México', 'Guanajuato', 'Guerrero', 'Hidalgo', 'Jalisco', 'Michoacán', 'Morelos', 'Nayarit', 'Nuevo León', 'Oaxaca', 'Puebla', 'Querétaro', 'Quintana Roo', 'San Luis Potosí', 'Sinaloa', 'Sonora', 'Tabasco', 'Tamaulipas', 'Tlaxcala', 'Veracruz', 'Yucatán', 'Zacatecas']}
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <TextInput
                                    formFieldStatus={ formFields['pais'].status }
                                    label="País"
                                    name="pais"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ true }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ pais }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <ZipCodeInput
                                    formFieldStatus={ formFields['codigoPostal'].status }
                                    label="Código postal"
                                    name="codigoPostal"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ true }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ codigoPostal }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md">
                                <EmailInput
                                    formFieldStatus={ formFields['email'].status }
                                    label="Correo electrónico"
                                    name="email"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ true }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ email }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <TelInput
                                    formFieldStatus={ formFields['telefono'].status }
                                    label="Teléfono fijo"
                                    name="telefono"
                                    onChange={ onInputChange }
                                    required={ false }
                                    requiredIndicator={ false }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ telefono }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <TelInput
                                    formFieldStatus={ formFields['celular'].status }
                                    label="Teléfono móvil"
                                    name="celular"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ true }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ celular }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md">
                                <div className='d-flex justify-content-end'>
                                    <LightButton
                                        disabled={isPending || formValidationStatus === 'checking'}
                                        icon="X"
                                        marginRight={true}
                                        onClick={handleCancelarCrearConsultorio}
                                        text="Cancelar"
                                        type="button"
                                    />
                                    <PrimaryButton
                                        disabled={isPending || formValidationStatus === 'checking'}
                                        icon="Plus"
                                        text="Crear"
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

ConsultorioCreate.propTypes = {
    handleCancelarCrearConsultorio: PropTypes.func.isRequired
  }