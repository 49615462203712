import { useQuery } from "@tanstack/react-query"

import { getConsulta } from "../services/configuracionconsulta.service";

export const useConfiguracionConsulta = () => {

    const configuracionConsultaQuery = useQuery({
        queryKey: ['configuracionExpedienteClinico/consulta'],
        queryFn: getConsulta,
        staleTime: 1000 * 60 * 60, // 1 hora
    });

    return {
        configuracionConsultaQuery,
    }
}