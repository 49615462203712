import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const PresionArterialInput = ({ formFieldStatus='checked', label, name, onChange, placeholder, required = false, requiredIndicator = false, setFormFieldValidation, value }) => {

  const [inputValidation, setInputValidation] = useState({ isValid: null, msg: null });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputValidation = useCallback(() => {
    if(value !== ''){
        setInputValidation({ isValid: true, msg: null });
        if(setFormFieldValidation) setFormFieldValidation(name, true);
    }else{
        if(required){
            setInputValidation({ isValid: false, msg: label + ' no válido' });
            if(setFormFieldValidation) setFormFieldValidation(name, false);
        }else{
            setInputValidation({ isValid: true, msg: null });
            if(setFormFieldValidation) setFormFieldValidation(name, true);
        }
    }
  })

  const handleChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^0-9/]/g, "");
    e.target.value = sanitizedValue;
    onChange(e);
  }

  useEffect(() => {
    if(formFieldStatus === 'checking') handleInputValidation();
  }, [formFieldStatus, handleInputValidation]);
  
  return (
    <>
      <label htmlFor={ name } className={styles.customInputLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>
      <input type="text" className={"form-control " + styles.customInputBasic + ((inputValidation.isValid != null && !inputValidation.isValid) ? " " + styles.customErrorInputBasic : "")} placeholder={ placeholder } name={ name } value={ value } onChange={ handleChange } onKeyUp={handleInputValidation} required={required} />
      <div className={styles.helperErrorText + ((inputValidation.isValid != null && !inputValidation.isValid) ? "" : " d-none" )}>{(inputValidation.isValid != null && !inputValidation.isValid) && inputValidation.msg}</div>
    </>
  )
}

PresionArterialInput.propTypes = {
  formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  requiredIndicator: PropTypes.bool,
  setFormFieldValidation: PropTypes.func,
  value: PropTypes.string,
}