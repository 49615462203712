import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { usePacienteInmunoterapiaPrevia } from '../hooks/usePacienteInmunoterapiaPrevia';

import { Error, LightButton, Loader, ModalContainer, PrimaryButton, TextInputPreview } from '../../../components';
import { DetalleInmunoterapiaModal } from '../../Modals/DetalleInmunoterapia';
import { HistorialInmunoterapiaModal } from '../../Modals/HistorialInmunoterapia';

import styles from '../PacienteConsulta.module.css';

export const InmunoterapiaPrevia = ({ id }) => {

  const { pacienteInmunoterapiaPreviaQuery: { isLoading, isError, data: inmunoterapias } } = usePacienteInmunoterapiaPrevia(id);

  const [inmunoterapiaActiva, setInmunoterapiaActiva] = useState(null);

  const [statusDetallesModal, setStatusDetallesModal] = useState(false);

  const [statusHistorialModal, setStatusHistorialModal] = useState(false);

  const showDetallesModal = () => {
    setStatusDetallesModal(true);
  }

  const hideDetallesModal = () => {
    setStatusDetallesModal(false);
  }

  const handleDetalles = (inmuno) => {
    setInmunoterapiaActiva(inmuno);
    showDetallesModal();
  }

  const showHistorialModal = () => {
    setStatusHistorialModal(true);
  }

  const hideHistorialModal = () => {
    setStatusHistorialModal(false);
  }

  const handleHistorial = (inmuno) => {
    setInmunoterapiaActiva(inmuno);
    showHistorialModal();
  }
  
  return (
    <>
        {(isLoading)
        ? <Loader />
        : <>
            {(isError)
                ? <Error />
                : <>
                    {inmunoterapias.map(inmunoterapia => <div key={inmunoterapia._id}>
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <TextInputPreview
                                    label={`${ inmunoterapia.tipo } - ${ inmunoterapia.fase } - Frasco ${ inmunoterapia.identificador }`}
                                    name="ultimaAplicacion"
                                    value={`${ inmunoterapia.ultimaAplicacionFechaFormato } - ${inmunoterapia.ultimaAplicacionDosis} ${inmunoterapia.tipoDosis}`}
                                />
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <div className="d-flex justify-content-end">
                                    <PrimaryButton
                                        inverse={true}
                                        marginRight={true}
                                        marginTop={false}
                                        onClick={() => handleDetalles(inmunoterapia)}
                                        text='Ver Detalles'
                                        type='button'
                                    />
                                    <LightButton
                                        marginTop={false}
                                        onClick={() => handleHistorial(inmunoterapia)}
                                        text="Historial"
                                        type="button"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.hrLabRad}></div>
                    </div>)}
                    { (statusDetallesModal) &&
                    <ModalContainer
                        state={ statusDetallesModal }
                        hide={ hideDetallesModal }
                        content={ <DetalleInmunoterapiaModal hide={ hideDetallesModal } data={(inmunoterapiaActiva !== null) ? inmunoterapiaActiva : {}} /> }
                    />}
                    { (statusHistorialModal) &&
                    <ModalContainer
                        state={ statusHistorialModal }
                        hide={ hideHistorialModal }
                        content={ <HistorialInmunoterapiaModal hide={ hideHistorialModal } data={(inmunoterapiaActiva !== null) ? inmunoterapiaActiva : {}} /> }
                    />}
                </>
            }
        </>
        }
    </>
  )
}

InmunoterapiaPrevia.propTypes = {
  id: PropTypes.string.isRequired,
}