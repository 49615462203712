import React, { useState } from 'react'
import PropTypes from 'prop-types';

import { useConsulta } from '../hooks/useConsulta';
import { useForm, useFormValidation } from '../../../../../hooks';

import { CheckboxInput, LightButton, PrimaryButton } from "../../../../../components";

import styles from './../Consulta.module.css';

export const ConsultaUpdate = ({ consulta }) => {

    const { updateConsultaMutation: { mutate: updateConsulta, isPending } } = useConsulta();

    const [dataStatus, setDataStatus] = useState(false);

    const { motivoConsulta, peea, fecha, habitusExterior, cabezaCuello, torax, abdomen, piel, extremidades, genitales, otro, frecuenciaCardiaca, frecuenciaRespiratoria, presionArterial, temperatura, sa02, peso, talla, imc, fem, trueTest3, contadorDosis, onInputChange, onResetForm } = useForm({ motivoConsulta: consulta.historiaClinica.motivoConsulta, peea: consulta.historiaClinica.peea, fecha: consulta.historiaClinica.fecha, habitusExterior: consulta.exploracionFisica.habitusExterior, cabezaCuello: consulta.exploracionFisica.cabezaCuello, torax: consulta.exploracionFisica.torax, abdomen: consulta.exploracionFisica.abdomen, piel: consulta.exploracionFisica.piel, extremidades: consulta.exploracionFisica.extremidades, genitales: consulta.exploracionFisica.genitales, otro: consulta.exploracionFisica.otro, frecuenciaCardiaca: consulta.signosVitales.frecuenciaCardiaca, frecuenciaRespiratoria: consulta.signosVitales.frecuenciaRespiratoria, presionArterial: consulta.signosVitales.presionArterial, temperatura: consulta.signosVitales.temperatura, sa02: consulta.signosVitales.sa02, peso: consulta.signosVitales.peso, talla: consulta.signosVitales.talla, imc: consulta.signosVitales.imc, fem: consulta.signosVitales.fem, trueTest3: consulta.medicamentos.trueTest3, contadorDosis: consulta.medicamentos.contadorDosis });

    const { isFormValid, formValidationStatus, startFormValidation } = useFormValidation([{field: 'motivoConsulta', default: true}, {field: 'peea', default: true}, {field: 'fecha', default: true}, {field: 'habitusExterior', default: true}, {field: 'cabezaCuello', default: true}, {field: 'torax', default: true}, {field: 'abdomen', default: true}, {field: 'piel', default: true}, {field: 'extremidades', default: true}, {field: 'genitales', default: true}, {field: 'otro', default: true}, {field: 'frecuenciaCardiaca', default: true}, {field: 'frecuenciaRespiratoria', default: true}, {field: 'presionArterial', default: true}, {field: 'temperatura', default: true}, {field: 'sa02', default: true}, {field: 'peso', default: true}, {field: 'talla', default: true}, {field: 'imc', default: true}, {field: 'fem', default: true}, {field: 'trueTest3', default: true}, {field: 'contadorDosis', default: true}]);

    const handleChange = (e) => {
        setDataStatus(true);
        onInputChange(e);
    }

    const handleCancelChange = () => {
        onResetForm();
        setDataStatus(false);
    }

    const onSubmit = async ( event ) => {
        event.preventDefault();
        
        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
            updateConsulta({ data: { motivoConsulta, peea, fecha, habitusExterior, cabezaCuello, torax, abdomen, piel, extremidades, genitales, otro, frecuenciaCardiaca, frecuenciaRespiratoria, presionArterial, temperatura, sa02, peso, talla, imc, fem, trueTest3, contadorDosis } });
            setDataStatus(false);
        }else{
          startFormValidation();
        }
    
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className={styles.panel}>
                    <div className={styles.subHeader}>Historia clinica</div>
                    <div className="row">
                        <div className="col">
                            <CheckboxInput
                                label="Motivo de consulta"
                                name="motivoConsulta"
                                onChange={ handleChange }
                                required={false}
                                checked={ (motivoConsulta === 'true' || motivoConsulta === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="PEEA"
                                name="peea"
                                onChange={ handleChange }
                                required={false}
                                checked={ (peea === 'true' || peea === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="Fecha"
                                name="fecha"
                                onChange={ handleChange }
                                required={false}
                                checked={ (fecha === 'true' || fecha === true) ? true : false }
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.panel}>
                    <div className={styles.subHeader}>Exploración Física</div>
                    <div className="row mb-3">
                        <div className="col">
                            <CheckboxInput
                                label="Habitus exterior"
                                name="habitusExterior"
                                onChange={ handleChange }
                                required={false}
                                checked={ (habitusExterior === 'true' || habitusExterior === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="Cabeza y cuello"
                                name="cabezaCuello"
                                onChange={ handleChange }
                                required={false}
                                checked={ (cabezaCuello === 'true' || cabezaCuello === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="Tórax"
                                name="torax"
                                onChange={ handleChange }
                                required={false}
                                checked={ (torax === 'true' || torax === true) ? true : false }
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <CheckboxInput
                                label="Abdomen"
                                name="abdomen"
                                onChange={ handleChange }
                                required={false}
                                checked={ (abdomen === 'true' || abdomen === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="Piel"
                                name="piel"
                                onChange={ handleChange }
                                required={false}
                                checked={ (piel === 'true' || piel === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="Extremidades"
                                name="extremidades"
                                onChange={ handleChange }
                                required={false}
                                checked={ (extremidades === 'true' || extremidades === true) ? true : false }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <CheckboxInput
                                label="Genitales"
                                name="genitales"
                                onChange={ handleChange }
                                required={false}
                                checked={ (genitales === 'true' || genitales === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="Otro"
                                name="otro"
                                onChange={ handleChange }
                                required={false}
                                checked={ (otro === 'true' || otro === true) ? true : false }
                            />
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
                <div className={styles.panel}>
                    <div className={styles.subHeader}>Signos vitales</div>
                    <div className="row mb-3">
                        <div className="col">
                            <CheckboxInput
                                label="Frecuencia cardiaca"
                                name="frecuenciaCardiaca"
                                onChange={ handleChange }
                                required={false}
                                checked={ (frecuenciaCardiaca === 'true' || frecuenciaCardiaca === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="Frecuencia respiratoria"
                                name="frecuenciaRespiratoria"
                                onChange={ handleChange }
                                required={false}
                                checked={ (frecuenciaRespiratoria === 'true' || frecuenciaRespiratoria === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="Presión arterial"
                                name="presionArterial"
                                onChange={ handleChange }
                                required={false}
                                checked={ (presionArterial === 'true' || presionArterial === true) ? true : false }
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <CheckboxInput
                                label="Temperatura"
                                name="temperatura"
                                onChange={ handleChange }
                                required={false}
                                checked={ (temperatura === 'true' || temperatura === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="SaO2 (%)"
                                name="sa02"
                                onChange={ handleChange }
                                required={false}
                                checked={ (sa02 === 'true' || sa02 === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="Peso (kg)"
                                name="peso"
                                onChange={ handleChange }
                                required={false}
                                checked={ (peso === 'true' || peso === true) ? true : false }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <CheckboxInput
                                label="Talla (cm)"
                                name="talla"
                                onChange={ handleChange }
                                required={false}
                                checked={ (talla === 'true' || talla === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="IMC (calculado)"
                                name="imc"
                                onChange={ handleChange }
                                required={false}
                                checked={ (imc === 'true' || imc === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="FEM (ml)"
                                name="fem"
                                onChange={ handleChange }
                                required={false}
                                checked={ (fem === 'true' || fem === true) ? true : false }
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.panel}>
                    <div className={styles.subHeader}>Medicamentos</div>
                    <div className="row">
                        <div className="col">
                            <CheckboxInput
                                label="T.R.U.E. test3"
                                name="trueTest3"
                                onChange={ handleChange }
                                required={false}
                                checked={ (trueTest3 === 'true' || trueTest3 === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="Contador de dosis"
                                name="contadorDosis"
                                onChange={ handleChange }
                                required={false}
                                checked={ (contadorDosis === 'true' || contadorDosis === true) ? true : false }
                            />
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-12 col-md">
                        <div className='d-flex justify-content-end'>
                            <LightButton
                                disabled={isPending || formValidationStatus === 'checking' || !dataStatus}
                                icon="X"
                                marginTop={false}
                                marginRight={true}
                                onClick={handleCancelChange}
                                text="Cancelar"
                                type="button"
                            />
                            <PrimaryButton
                                disabled={isPending || formValidationStatus === 'checking' || !dataStatus}
                                icon="Check"
                                marginTop={false}
                                text="Guardar"
                                type="submit"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

ConsultaUpdate.propTypes = {
    consulta: PropTypes.object.isRequired,
}