import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import * as Icon from 'react-bootstrap-icons';

import { colors } from '../../types/types';
import styles from './FormFields.module.css';

const CustomOption = (props) => {
    const { data, isSelected, innerRef, innerProps } = props;

    return (
      <div ref={innerRef} {...innerProps} style={{ display: "flex", alignItems: "center", padding: "10px", cursor: "pointer", backgroundColor: "#fff" }}>
        {data.label}{(isSelected) && <Icon.CheckLg className='ms-1' size={20} color={colors.SUCCESS} />}
      </div>
    );
};

export const ReactMultiValueSelectInput = ({ formFieldStatus='checked', label = '', name, onChange, placeholder = '', required = false, requiredIndicator = false, setFormFieldValidation, value, values}) => {

  const css = `
        .select__control--is-focused{
            border-color: #86b7fe !important;
            box-shadow: none !important;
        }
    `;

    let valueAux = value.map(data => ({
        value: data._id,
        label: data.nombre
    }));

    let options = values.map(data => ({
        value: data._id,
        label: data.nombre
    }));

    const [inputValidation, setInputValidation] = useState({ isValid: null, msg: null });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputValidation = useCallback(() => {
        if(value.length > 0){
            setInputValidation({ isValid: true, msg: null });
            if(setFormFieldValidation) setFormFieldValidation(name, true);

        }else{
            if(required){
                setInputValidation({ isValid: false, msg: label + ' no válido' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
            }else{
                setInputValidation({ isValid: true, msg: null });
                if(setFormFieldValidation) setFormFieldValidation(name, true);
            }
        }
    })

    const handleOnChange = (values) => {
        onChange({ target: { name: name, value: values.map(valueData => ({ _id: valueData.value, nombre: valueData.label })) } });
    }

    useEffect(() => {
        handleInputValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        if(formFieldStatus === 'checking') handleInputValidation();
      }, [formFieldStatus, handleInputValidation]);
  
    return (
        <>
            <style>{ css }</style>
            {(label !== '') && <label htmlFor={ name } className={styles.customPreviewLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>}
            <Select
                className={"basic-multi-select " + styles.basicMultiSelect}
                classNamePrefix="select"
                closeMenuOnSelect={false}
                components={{ Option: CustomOption }}
                hideSelectedOptions={false}
                isMulti
                name={name}
                onChange={handleOnChange}
                options={options}
                placeholder={placeholder}
                value={valueAux}
            />
            <div className={styles.helperErrorTextReactSelect + ((inputValidation.isValid != null && !inputValidation.isValid) ? "" : " d-none" )}>{(inputValidation.isValid != null && !inputValidation.isValid) && inputValidation.msg}</div>
        </>
    )
}

ReactMultiValueSelectInput.propTypes = {
    formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    requiredIndicator: PropTypes.bool,
    setFormFieldValidation: PropTypes.func,
    value: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired,
}