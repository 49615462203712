import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { confirmarCita } from "../services/citas.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const useCitaConfirmar = (fecha) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const confirmarCitaMutation = useMutation({
        mutationFn: confirmarCita,
        onSuccess: (cita, variables, context) => {
            queryClient.setQueryData(['agendaInmunoterapia', { fecha }], oldData => {
                return {
                  ...oldData,
                  inmunoterapias: oldData.inmunoterapias.map(citaAux => {
                    if(String(citaAux._id) === String(cita._id)){
                        return {
                            ...citaAux,
                            confirmada: cita.confirmada,
                            etiqueta: cita.etiqueta,
                            etiquetaColor: cita.etiquetaColor
                        }
                    }else{
                        return citaAux;
                    }
                  })
                };
              });
            dispatch(successNotification('Cita confirmada'));
        },
        onError: (error, variables, context) => {
            dispatch(dangerNotification(error.message));
            // dispatch(dangerNotification(error.msg.message));
        }
    });

    return {
        confirmarCitaMutation,
    }
}