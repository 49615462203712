import PropTypes from 'prop-types';

import { useAsistentes } from '../hooks/useAsistentes';
import { useForm, useFormValidation } from '../../../hooks';

import { CheckboxInput, DateInput, EmailInput, ImgCircledInput, LightButton, PasswordConfirmationInput, PasswordInput, PrimaryButton, ReactSelectSingleInput, TextInput } from '../../../components';

import { eliminarUndefined } from '../../../helpers';

import styles from '../Asistentes.module.css';

export const AsistenteCreate = ({ handleCancelarCrearAsistente }) => {

    const { createAsistenteMutation: { mutate: createAsistente, isPending } } = useAsistentes();

    const { nombre, apellidoPaterno, apellidoMaterno, fechaNacimiento, genero, email, password, confirmacion, img, activo, onInputChange } = useForm({ nombre: '', apellidoPaterno: '', apellidoMaterno: '', fechaNacimiento: '', genero: '', email: '', password: '', confirmacion: '', img: null, activo: true });

    const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'nombre', default: false}, {field: 'apellidoPaterno', default: false}, {field: 'apellidoMaterno', default: true}, {field: 'fechaNacimiento', default: false}, {field: 'genero', default: false}, {field: 'email', default: false}, {field: 'password', default: false}, {field: 'confirmacion', default: false}, {field: 'img', default: true}, {field: 'activo', default: true}]);

    const onSubmit = async ( event ) => {
        event.preventDefault();
        
        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
            createAsistente({ data: eliminarUndefined({ nombre, apellidoPaterno, apellidoMaterno, fechaNacimiento, genero, email, password, img, activo }, { apellidoMaterno: undefined }, ['img']), callback: handleCancelarCrearAsistente });
        }else{
            startFormValidation();
        }
    }

    return (
        <div className={styles.panel}>
            <form onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-12 col-lg-2">
                        <ImgCircledInput
                            formFieldStatus={ formFields['img'].status }
                            imgText="Img"
                            name="img"
                            onChange={ onInputChange }
                            required={ false }
                            requiredIndicator={ false }
                            setFormFieldValidation={ setFormFieldValidation }
                            value={img}
                        />
                    </div>
                    <div className="col-md-12 col-lg-10">
                        <div className="row">
                            <div className="col-sm-12 col-md">
                                <TextInput
                                    formFieldStatus={ formFields['nombre'].status }
                                    label="Nombre(s)"
                                    name="nombre"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ true }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ nombre }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <TextInput
                                    formFieldStatus={ formFields['apellidoPaterno'].status }
                                    label="Apellido paterno"
                                    name="apellidoPaterno"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ true }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ apellidoPaterno }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <TextInput
                                    formFieldStatus={ formFields['apellidoMaterno'].status }
                                    label="Apellido materno"
                                    name="apellidoMaterno"
                                    onChange={ onInputChange }
                                    required={ false }
                                    requiredIndicator={ false }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ apellidoMaterno }
                                />
                            </div>
                            <div className="col-sm-12 col-md d-flex align-items-center">
                                <CheckboxInput
                                label="Asistente activo"
                                name="activo"
                                onChange={ onInputChange }
                                required={false}
                                checked={ (activo === 'true' || activo === true) ? true : false }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md">
                                <DateInput
                                    formFieldStatus={ formFields['fechaNacimiento'].status }
                                    label="Fecha de nacimiento"
                                    name="fechaNacimiento"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ true }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ fechaNacimiento }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <ReactSelectSingleInput
                                    formFieldStatus={ formFields['genero'].status }
                                    label="Género"
                                    name="genero"
                                    onChange={ onInputChange }
                                    required={true}
                                    requiredIndicator={true}
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={genero}
                                    values={['Masculino', 'Femenino', 'Otro', 'Prefiero no decirlo']}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md">
                                <EmailInput
                                    formFieldStatus={ formFields['email'].status }
                                    label="Correo electrónico"
                                    name="email"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ true }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ email }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <PasswordInput
                                    formFieldStatus={ formFields['password'].status }
                                    label="Contraseña"
                                    name="password"
                                    onChange={ onInputChange }
                                    required={true}
                                    requiredIndicator={true}
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ password }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <PasswordConfirmationInput
                                    formFieldStatus={ formFields['confirmacion'].status }
                                    label="Confirmar contraseña"
                                    name="confirmacion"
                                    password={ password }
                                    onChange={ onInputChange }
                                    required={true}
                                    requiredIndicator={true}
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ confirmacion }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md">
                                <div className='d-flex justify-content-end'>
                                    <LightButton
                                        disabled={isPending || formValidationStatus === 'checking'}
                                        icon="X"
                                        marginRight={true}
                                        onClick={handleCancelarCrearAsistente}
                                        text="Cancelar"
                                        type="button"
                                    />
                                    <PrimaryButton
                                        disabled={isPending || formValidationStatus === 'checking'}
                                        icon="Plus"
                                        text="Crear"
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

AsistenteCreate.propTypes = {
    handleCancelarCrearAsistente: PropTypes.func.isRequired
  }