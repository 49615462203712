import { inmunetApi } from '../../../../../api';

export const getPruebasAlergia = async() => {

    try {

        const { data } = await inmunetApi.get('/configuracionExpedienteClinico/pruebas-alergia');

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.pruebasAlergia;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const updatePruebasAlergia = async({ data: pruebasAlergia }) => {

    try {

        let formData = new FormData();
        Object.keys(pruebasAlergia).forEach(field => {
            formData.append(field, pruebasAlergia[field]);
        });

        const { data } = await inmunetApi.put(`/configuracionExpedienteClinico/pruebas-alergia`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al actualizar datos';

        return data.pruebasAlergia;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error('Error al actualizar datos');
        }

    }

};