import { useQuery } from "@tanstack/react-query"

import { getAgendaInmunoterapia } from "../services/inmunoterapia.service";

export const useInmunoterapia = (fecha) => {

    const agendaInmunoterapiaQuery = useQuery({
        queryKey: ['agendaInmunoterapia', { fecha }],
        queryFn: () => getAgendaInmunoterapia(fecha),
        staleTime: 0, // nulo
        placeholderData: {
            inmunoterapias: []
        }
    });

    return {
        agendaInmunoterapiaQuery,
    }
}