import PropTypes from 'prop-types';

import { useDatosGenerales } from '../hooks/useDatosGenerales';
import { useForm, useFormValidation } from '../../../hooks';

import { DateInput, EmailInput, ImgCircledInput, LightButton, PrimaryButton, ReactSelectSingleInput, TelInput, TextInput } from "../../../components";

import { eliminarUndefined } from '../../../helpers';

export const DatosGeneralesUpdate = ({ usuario, handleCancelarEditarDatosGenerales }) => {

    const { updateDatosGeneralesMutation: { mutate: updateUsuario, isPending } } = useDatosGenerales();

    const { nombre, apellidoPaterno, apellidoMaterno, fechaNacimiento, genero, email, celular, especialidad, img, onInputChange } = useForm({ nombre: usuario.nombre, apellidoPaterno: usuario.apellidoPaterno, apellidoMaterno: (typeof usuario.apellidoMaterno !== 'undefined') ? usuario.apellidoMaterno : '', fechaNacimiento: usuario.fechaNacimiento, genero: usuario.genero, email: usuario.email, celular: (typeof usuario.celular !== 'undefined') ? usuario.celular : '', especialidad: usuario.especialidad, img: usuario.img });

    const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'nombre', default: true}, {field: 'apellidoPaterno', default: true}, {field: 'apellidoMaterno', default: true}, {field: 'fechaNacimiento', default: true}, {field: 'genero', default: true}, {field: 'email', default: true}, {field: 'celular', default: true}, {field: 'especialidad', default: true}, {field: 'img', default: true}]);

    const onSubmit = async ( event ) => {
        event.preventDefault();
        
        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
            updateUsuario({ data: eliminarUndefined({ nombre, apellidoPaterno, apellidoMaterno, fechaNacimiento, genero, email, celular, especialidad, img }, { apellidoMaterno: usuario.apellidoMaterno, celular: usuario.celular }, ['img']), callback: handleCancelarEditarDatosGenerales });
        }else{
          startFormValidation();
        }
    
    }

    return (
        <>
            <form onSubmit={ onSubmit }>
                <div className="row">
                    <div className="col-md-12 col-lg-2">
                        <ImgCircledInput
                            formFieldStatus={ formFields['img'].status }
                            imgText="Img"
                            name="img"
                            onChange={ onInputChange }
                            required={false}
                            requiredIndicator={false}
                            setFormFieldValidation={ setFormFieldValidation }
                            value={img}
                        />
                    </div>
                    <div className="col-md-12 col-lg-10">
                        <div className="row">
                            <div className="col-sm-12 col-md">
                                <TextInput
                                    formFieldStatus={ formFields['nombre'].status }
                                    label="Nombre(s)"
                                    name="nombre"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ false }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ nombre }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <TextInput
                                    formFieldStatus={ formFields['apellidoPaterno'].status }
                                    label="Apellido paterno"
                                    name="apellidoPaterno"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ false }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ apellidoPaterno }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <TextInput
                                    formFieldStatus={ formFields['apellidoMaterno'].status }
                                    label="Apellido materno"
                                    name="apellidoMaterno"
                                    onChange={ onInputChange }
                                    required={ false }
                                    requiredIndicator={ false }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ apellidoMaterno }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <ReactSelectSingleInput
                                    formFieldStatus={ formFields['genero'].status }
                                    label="Género"
                                    name="genero"
                                    onChange={ onInputChange }
                                    required={true}
                                    requiredIndicator={true}
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={genero}
                                    values={['Masculino', 'Femenino', 'Otro', 'Prefiero no decirlo']}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md">
                                <DateInput
                                    formFieldStatus={ formFields['fechaNacimiento'].status }
                                    label="Fecha de nacimiento"
                                    name="fechaNacimiento"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ false }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ fechaNacimiento }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <ReactSelectSingleInput
                                    formFieldStatus={ formFields['especialidad'].status }
                                    label="Especialidad"
                                    name="especialidad"
                                    onChange={ onInputChange }
                                    required={true}
                                    requiredIndicator={true}
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={especialidad}
                                    values={['Alergia e Inmunología Clínica', 'Alergología pediátrica']}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md">
                                <TelInput
                                    formFieldStatus={ formFields['celular'].status }
                                    label="Celular"
                                    name="celular"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ false }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ celular }
                                />
                            </div>
                            <div className="col-sm-12 col-md">
                                <EmailInput
                                    formFieldStatus={ formFields['email'].status }
                                    label="Correo electrónico"
                                    name="email"
                                    onChange={ onInputChange }
                                    required={ true }
                                    requiredIndicator={ false }
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ email }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='d-flex justify-content-end'>
                                    <LightButton
                                        disabled={isPending || formValidationStatus === 'checking'}
                                        icon="X"
                                        marginRight={true}
                                        onClick={handleCancelarEditarDatosGenerales}
                                        text="Cancelar"
                                        type="button"
                                    />
                                    <PrimaryButton
                                        disabled={isPending || formValidationStatus === 'checking'}
                                        icon="Check"
                                        text="Guardar"
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

DatosGeneralesUpdate.propTypes = {
    usuario: PropTypes.object.isRequired,
    handleCancelarEditarDatosGenerales: PropTypes.func.isRequired
}