import { inmunetApi } from '../../../../api';

export const independizarPaciente = async({ data: paciente }) => {

    try {

        let formData = new FormData();
        formData.append('email', paciente.email);

        const { data } = await inmunetApi.put(`paciente/asociado/${ paciente.id }`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al independizar paciente';

        return data.paciente;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error('Error al independizar paciente');
        }

    }

};