import { inmunetApi } from '../../../api';

export const getCitas = async(id, pag, limite) => {

    const params = new URLSearchParams();
    params.append('paciente', `${id}`);
    params.append('pag', `${pag}`);
    params.append('limite', `${limite}`);

    try {

        const { data } = await inmunetApi.get('/cita?paginacion=true', {
            params,
        });

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const getHistorial = async(id) => {

    const params = new URLSearchParams();
    params.append('paciente', `${id}`);

    try {

        const { data } = await inmunetApi.get('/cita/pasadas?paginacion=false', {
            params,
        });

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const confirmarCita = async({ id }) => {

    try {

        const { data } = await inmunetApi.put(`/cita/confirmar/${ id }`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al confirmar cita';

        return data.cita;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error('Error al confirmar cita');
        }

    }

};

export const atenderCita = async({ id }) => {

    try {

        const { data } = await inmunetApi.put(`/cita/atender/${ id }`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al atender cita';

        return data.cita;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error('Error al atender cita');
        }

    }

};

export const cancelarCita = async({ id }) => {

    try {

        const { data } = await inmunetApi.put(`/cita/cancelar/${ id }`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al cancelar cita';

        return data.cita;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error('Error al cancelar cita');
        }

    }

};

export const noPresentarCita = async({ id }) => {

    try {

        const { data } = await inmunetApi.put(`/cita/noPresentar/${ id }`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al no presentar cita';

        return data.cita;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error('Error al no presentar cita');
        }

    }

};

export const eliminarCita = async({ id }) => {

    try {

        const { data } = await inmunetApi.delete(`/cita/${ id }`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al eliminar cita';

        return id;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error('Error al eliminar cita');
        }

    }

};