import { useEffect } from "react";
import PropTypes from 'prop-types';

import { useForm, useFormValidation } from "../../../../hooks";

import { DateTimeInput, IconButton, NumberInput, ReactSelectSingleInput, ReactSelectSingleKeyInput, TextInputPreview } from "../../.."

import { colors } from "../../../../types/types";

import styles from '../MultiFormAplicaccionesInmunoterapiaNueva.module.css';

export const AplicacionForm = ({ element, first, formStatus='checked', index, ladoFlag = true, lados = [], last, onFormChange, reaccionFlag = true, removeElement, tipo = '' }) => {

  const { fecha, dosis, ladoAplicacion, reaccion, realizado, onInputChange } = useForm({ fecha: element.fecha, dosis: element.dosis, ladoAplicacion: element.ladoAplicacion, reaccion: element.reaccion, realizado: element.realizado });

  const { isFormValid, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'fecha', default: true}, {field: 'dosis', default: true}, {field: 'ladoAplicacion', default: true}, {field: 'reaccion', default: true}, {field: 'realizado', default: true}]);

  useEffect(() => {
    if(onFormChange) onFormChange({ id: element.id, fecha, dosis, ladoAplicacion, reaccion, realizado: (realizado === 'true'), validation: isFormValid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fecha, dosis, ladoAplicacion, reaccion, realizado, isFormValid])

  useEffect(() => {
    if(formStatus === 'checking') startFormValidation();
  }, [formStatus, startFormValidation]);

  let fechaSpace = 2;
  let inputSpace = 2;
  let dosisSpace = 2;
  if(!ladoFlag && !reaccionFlag){
    fechaSpace += 2;
    inputSpace++;
    dosisSpace++;
  }else{
      if(!ladoFlag || !reaccionFlag){
        fechaSpace += 2;
      }
  }

  let dosisLabel = 'Dosis (ml)';
  let dosisStep = 0.01;
  let dosisInt = false;
  if(tipo === 'Sublingual' || tipo === 'Oraltek'){
      dosisLabel = 'Gotas';
      dosisStep = 1;
      dosisInt = true;
  }

  return (
    <>
        <div className="row">
            <div className={`col-md-12 col-lg-1 pe-1`}>
                <div className="mt-2"></div>
                <TextInputPreview
                    label="&nbsp;"
                    name="index"
                    showLabel={ (first) ? true : false }
                    value={ String(index) }
                />
            </div>
            <div className={`col-md-12 col-lg-${ fechaSpace } ps-1 pe-1`}>
                <DateTimeInput
                    formFieldStatus={ formFields['fecha'].status }
                    label={(first) ? "Fecha" : ""}
                    name="fecha"
                    onChange={ onInputChange }
                    placeholder="dd/mm/aaaa hh:mm"
                    required={ true }
                    requiredIndicator={ (first) ? true : false }
                    setFormFieldValidation={ setFormFieldValidation }
                    showLabel={ (first) ? true : false }
                    value={ fecha }
                />
            </div>
            <div className={`col-md-12 col-lg-${ dosisSpace } ps-1 pe-1`}>
                <NumberInput
                    formFieldStatus={ formFields['dosis'].status }
                    label={(first) ? dosisLabel : ""}
                    name="dosis"
                    onChange={ onInputChange }
                    required={ true }
                    requiredIndicator={ (first) ? true : false }
                    setFormFieldValidation={ setFormFieldValidation }
                    showLabel={ (first) ? true : false }
                    step={dosisStep}
                    value={ (dosisInt) ? parseInt(dosis) : dosis }
                />
            </div>
            {(ladoFlag) &&
            <div className={`col-md-12 col-lg-${ inputSpace } ps-1 pe-1`}>
                <ReactSelectSingleInput
                    formFieldStatus={ formFields['ladoAplicacion'].status }
                    label={(first) ? "Lado" : ""}
                    name="ladoAplicacion"
                    onChange={ onInputChange }
                    required={true}
                    requiredIndicator={ (first) ? true : false }
                    setFormFieldValidation={ setFormFieldValidation }
                    showLabel={ (first) ? true : false }
                    value={ladoAplicacion}
                    values={lados}
                />
            </div>
            }
            {(reaccionFlag) && 
            <div className={`col-md-12 col-lg-${ inputSpace } ps-1 pe-1`}>
                <ReactSelectSingleInput
                    formFieldStatus={ formFields['reaccion'].status }
                    label={(first) ? "Reacción" : ""}
                    name="reaccion"
                    onChange={ onInputChange }
                    required={false}
                    requiredIndicator={ false }
                    setFormFieldValidation={ setFormFieldValidation }
                    showLabel={ (first) ? true : false }
                    value={reaccion}
                    values={ ['Leve', 'Grande', 'Generalizada'] }
                />
            </div>
            }
            <div className={`col-md-12 col-lg-${ inputSpace } ps-1 pe-1`}>
                <ReactSelectSingleKeyInput
                    formFieldStatus={ formFields['realizado'].status }
                    label={(first) ? "Estatus" : ""}
                    name="realizado"
                    onChange={ onInputChange }
                    required={true}
                    requiredIndicator={ (first) ? true : false }
                    setFormFieldValidation={ setFormFieldValidation }
                    showLabel={ (first) ? true : false }
                    value={String(realizado)}
                    values={[{ value: 'false', label: 'Pendiente' }, { value: 'true', label: 'Aplicada' }]}
                />
            </div>
            <div className="col-md-12 col-lg-1">
                {(last) &&
                <>
                    {(first) && <label>&nbsp;</label>}
                    <div className={styles.closeBtnContainer}>
                        <div>
                            <IconButton
                                icon="Trash3"
                                iconColor={colors.DANGER}
                                marginTop={false}
                                onClick={() => removeElement(element.id)}
                                type="button"
                                size={22}
                            />
                        </div>
                    </div>
                </>
                }
            </div>
        </div>
    </>
  )
}

AplicacionForm.propTypes = {
  element: PropTypes.any.isRequired,
  first: PropTypes.bool.isRequired,
  formStatus: PropTypes.oneOf(['checked', 'checking']),
  index: PropTypes.number.isRequired,
  ladoFlag: PropTypes.bool,
  lados: PropTypes.array,
  last: PropTypes.bool.isRequired,
  onFormChange: PropTypes.func,
  reaccionFlag: PropTypes.bool,
  removeElement: PropTypes.func.isRequired,
  tipo: PropTypes.string,
}