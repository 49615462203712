import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import styles from './MovilTabSelector.module.css';
import { colors } from '../../types/types';

export const MovilTabSelector = ({ onChange, value, values}) => {

  const css = `
        .select__control--is-focused{
            border-color: #86b7fe !important;
            box-shadow: none !important;
        }
    `;

    const customStyles = {
        singleValue: (provided) => ({
          ...provided,
          color: colors.PANEL_HEADER,
          fontWeight: "bold",
        }),
    };

    const handleOnChange = (val) => {
        onChange(parseInt(val.value));
    }
  
    return (
        <>
            <style>{ css }</style>
            <Select
                className={"basic-multi-select " + styles.basicMultiSelect}
                classNamePrefix="Tab"
                defaultValue={values.find(val => val.value === value)}
                isClearable={false}
                isSearchable={false}
                onChange={handleOnChange}
                options={values}
                styles={customStyles}
            />
        </>
    )
}

MovilTabSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
}