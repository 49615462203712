import { inmunetApi } from '../../../api';

export const getConfiguracionConsultorio = async(id) => {

    try {

        const { data } = await inmunetApi.get(`/consultorio/configuracion/${ id }`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.configuracion;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};