import { Outlet } from "react-router-dom";

import { CalendarioSelector, ConsultorioSelector } from "../../../../../components";

import styles from './CalendarioSider.module.css';

export const CalendarioSider = () => {
  return (
    <>
        <div className="row flex-grow-1">
            <div className={"col-sm-3 col-md-2 " + styles.sideContainer}>
                <CalendarioSelector />
                <ConsultorioSelector />
            </div>
            <div className={"col " + styles.contentColumn}><Outlet /></div>
        </div>
    </>
  )
}
