import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const RadiobuttonGroup = ({ formFieldStatus='checked', label, name, onChange, required = false, requiredIndicator = false, setFormFieldValidation, value, values}) => {

    const [inputValidation, setInputValidation] = useState({ isValid: null, msg: null });

    let valuesAux = values.map(option => option.key);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputValidation = useCallback(() => {
        if(valuesAux.includes(value)){
            setInputValidation({ isValid: true, msg: null });
            if(setFormFieldValidation) setFormFieldValidation(name, true);
        }else{
            if(value !== ''){
                setInputValidation({ isValid: false, msg: 'Selección no válida' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
            }else{
                // setInputValidation({ isValid: null, msg: null });
                if(required){
                    setInputValidation({ isValid: false, msg: 'Selección no válida' });
                    if(setFormFieldValidation) setFormFieldValidation(name, false);
                }else{
                    setInputValidation({ isValid: true, msg: null });
                    if(setFormFieldValidation) setFormFieldValidation(name, true);
                }
            }
        }
    });

    useEffect(() => {
        if(formFieldStatus === 'checking') handleInputValidation();
    }, [formFieldStatus, handleInputValidation]);

    useEffect(() => {
        handleInputValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        if(valuesAux.includes(value)){
            setInputValidation({ isValid: true, msg: null });
            if(setFormFieldValidation) setFormFieldValidation(name, true);
        }else{
            if(value === '' || value === undefined){
                setInputValidation({ isValid: null, msg: null });
                if(setFormFieldValidation) setFormFieldValidation(name, null);
            }else{
                setInputValidation({ isValid: false, msg: 'Selección no válida' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    return (
        <>
            <label htmlFor={ name } className={styles.customInputLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>
            <div className="row">
                { values.map(option => {
                    return <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3" key={option.key}>
                        <input className={"form-check-input " + styles.customRadiobutton} type="radio" name={ name } value={ option.key } checked={ (value === option.key) ? true : false } onChange={ onChange } required={required} />
                        <label className={"form-check-label ms-2 " + styles.customRadiobuttonText} htmlFor={ name }>{ option.label }</label>
                    </div>
                }) }
            </div>
            <div className={styles.helperErrorText + ((inputValidation.isValid != null && !inputValidation.isValid) ? "" : " d-none" ) + " mt-2"}>{(inputValidation.isValid != null && !inputValidation.isValid) && inputValidation.msg}</div>
        </>
    )
}

RadiobuttonGroup.propTypes = {
    formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    requiredIndicator: PropTypes.bool,
    setFormFieldValidation: PropTypes.func,
    value: PropTypes.string,
    values: PropTypes.array.isRequired,
}