import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { confirmarCita } from "../services/citas.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const useCitaConfirmar = ({ id, pag, limite }) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const confirmarCitaMutation = useMutation({
        mutationFn: confirmarCita,
        onSuccess: (cita, variables, context) => {
            queryClient.setQueryData(['citas', id, { pag, limite }], oldData => {
                return {
                  ...oldData,
                  citas: oldData.citas.map(citaAux => {
                    if(String(citaAux._id) === String(cita._id)){
                        return cita;
                    }else{
                        return citaAux;
                    }
                  })
                };
              });
            dispatch(successNotification('Cita confirmada'));
        },
        onError: (error, variables, context) => {
            variables.onComplete();
            dispatch(dangerNotification(error.message));
            // dispatch(dangerNotification(error.msg.message));
        }
    });

    return {
        confirmarCitaMutation,
    }
}