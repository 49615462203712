import { inmunetApi } from '../../../api';

export const getPacientes = async(pag, limite, search) => {

    const params = new URLSearchParams();
    params.append('pag', `${pag}`);
    params.append('limite', `${limite}`);
    if (search.length >= 3) params.append('search', `${search}`);

    try {

        const { data } = await inmunetApi.get('/paciente?paginacion=true', {
            params,
        });

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};