import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { initPaciente } from "../../redux/slices/consulta";

import { Radiologia } from './components/Radiologia';
import { Laboratorio } from './components/Laboratorio';
import { PruebaAlergia } from './components/PruebaAlergia';
import { Inmunoterapia } from './components/Inmunoterapia';
import { Consulta } from './components/Consulta';

import styles from './PacienteConsulta.module.css';

export const PacienteConsulta = () => {

  const dispatch = useDispatch();

  let { id } = useParams();

  const dataConsulta = useSelector(state => state.consulta[id]);

  const [tab, setTab] = useState(1);

  useEffect(() => {
    dispatch(initPaciente(id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-8">
          {(dataConsulta) && <Consulta data={dataConsulta} />}
        </div>
        <div className="col-sm-12 col-md-4">
          <div className={styles.tabsContainer}>
            <div className={"row"}>
              <div className={"col p-0 " + styles.tabContainer}>
                  <div className={styles.tab + " " + ((tab === 1) ? styles.tabInmunoterapiasActive : styles.tabInmunoterapias)} onClick={() => setTab(1)}></div>
              </div>
              <div className={"col p-0 " + styles.tabContainer}>
                  <div className={styles.tab + " " + ((tab === 2) ? styles.tabPruebasActive : styles.tabPruebas)} onClick={() => setTab(2)}></div>
              </div>
              <div className={"col p-0 " + styles.tabContainer}>
                  <div className={styles.tab + " " + ((tab === 3) ? styles.tabLaboratoriosActive : styles.tabLaboratorios) } onClick={() => setTab(3)}></div>
              </div>
              <div className={"col p-0 " + styles.tabContainer}>
                  <div className={styles.tab + " " + ((tab === 4) ? styles.tabRadiologiaActive : styles.tabRadiologia) } onClick={() => setTab(4)}></div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <hr />
              </div>
            </div>
            <div className="row">
              {(tab === 1) && <div className="col"><Inmunoterapia /></div>}
              {(tab === 2) && <div className="col"><PruebaAlergia /></div>}
              {(tab === 3) && <div className="col"><Laboratorio /></div>}
              {(tab === 4) && <div className="col"><Radiologia /></div>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
