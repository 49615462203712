import { useEffect } from "react";
import PropTypes from 'prop-types';

import { useForm, useFormValidation } from "../../../../hooks";

import { DateTimeInput, IconButton, NumberInput, ReactSelectSingleInput, ReactSelectSingleKeyInput, TextInputPreview } from "../../.."

import { colors } from "../../../../types/types";

import styles from '../MultiFormAplicacionesInmunoterapiaActiva.module.css';

export const AplicacionForm = ({ element, first, index, ladoFlag = true, lados = [], last, reaccionFlag = true, removeElement, tipo = '', updateElement }) => {

    const { fecha, dosis, ladoAplicacion, reaccion, realizado, onInputChange } = useForm({ fecha: element.fecha, dosis: element.dosis, ladoAplicacion: element.ladoAplicacion, reaccion: element.reaccion, realizado: element.realizado });

    const { formFields, setFormFieldValidation } = useFormValidation([{field: 'fecha', default: true}, {field: 'dosis', default: true}, {field: 'ladoAplicacion', default: true}, {field: 'reaccion', default: true}, {field: 'realizado', default: true}]);

    let fechaSpace = 2;
    let inputSpace = 2;
    let dosisSpace = 2;
    if(!ladoFlag && !reaccionFlag){
        fechaSpace += 2;
        inputSpace++;
        dosisSpace++;
    }else{
        if(!ladoFlag || !reaccionFlag){
            fechaSpace += 2;
        }
    }

    const handleInputFechaChange = (e) => {
        e.event.preventDefault();
        updateElement({ id: element.id, fecha: e.target.value, last });
    }

    const handleInputDosisChange = (e) => {
        e.preventDefault();
        updateElement({ id: element.id, dosis: (e.target.value !== '') ? e.target.value : 0, ladoAplicacion, reaccion });
    }

    const handleInputLadoAplicacionChange = (e) => {
        let ladoAplicacionAux = e.target.value;
        e.target.value = ladoAplicacion;
        updateElement({ id: element.id, dosis, ladoAplicacion: ladoAplicacionAux, reaccion });
    }

    const handleInputReaccionChange = (e) => {
        let reaccionAux = e.target.value;
        e.target.value = reaccion;
        updateElement({ id: element.id, dosis, ladoAplicacion, reaccion: reaccionAux });
    }

    const handleInputRealizadoChange = (e) => {
        let realizadoAux = e.target.value;
        e.target.value = realizado;
        updateElement({ id: element.id, realizado: realizadoAux });
    }

    useEffect(() => {
        // fecha, dosis, ladoAplicacion, reaccion, realizado
        if(element.fecha !== fecha){
            onInputChange({ target: { name: 'fecha', value: element.fecha } });
        }
        if(element.dosis !== dosis){
            onInputChange({ target: { name: 'dosis', value: element.dosis } });
        }
        if(element.ladoAplicacion !== ladoAplicacion){
            onInputChange({ target: { name: 'ladoAplicacion', value: element.ladoAplicacion } });
        }
        if(element.reaccion !== reaccion){
            onInputChange({ target: { name: 'reaccion', value: element.reaccion } });
        }
        if(element.realizado !== realizado){
            onInputChange({ target: { name: 'realizado', value: element.realizado } });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element])

    let dosisLabel = 'Dosis (ml)';
    let dosisStep = 0.01;
    let dosisInt = false;
    if(tipo === 'Sublingual' || tipo === 'Oraltek'){
        dosisLabel = 'Gotas';
        dosisStep = 1;
        dosisInt = true;
    }

    return (
        <>
            <div className="row">
                <div className={`col-md-12 col-lg-1 pe-1`}>
                    <div className="mt-2"></div>
                    <TextInputPreview
                        label="&nbsp;"
                        name="index"
                        showLabel={ (first) ? true : false }
                        value={ String(index) }
                    />
                </div>
                <div className={`col-md-12 col-lg-${ fechaSpace } ps-1 pe-1`}>
                    <DateTimeInput
                        formFieldStatus={ formFields['fecha'].status }
                        label={(first) ? "Fecha" : ""}
                        name="fecha"
                        onChange={ handleInputFechaChange }
                        placeholder="dd/mm/aaaa hh:mm"
                        required={ true }
                        requiredIndicator={ (first) ? true : false }
                        setFormFieldValidation={ setFormFieldValidation }
                        showLabel={ (first) ? true : false }
                        value={ fecha }
                    />
                </div>
                <div className={`col-md-12 col-lg-${ dosisSpace } ps-1 pe-1`}>
                    <NumberInput
                        formFieldStatus={ formFields['dosis'].status }
                        label={(first) ? dosisLabel : ""}
                        name="dosis"
                        onChange={ handleInputDosisChange }
                        required={ true }
                        requiredIndicator={ (first) ? true : false }
                        setFormFieldValidation={ setFormFieldValidation }
                        showLabel={ (first) ? true : false }
                        step={dosisStep}
                        value={ (dosisInt) ? parseInt(dosis) : dosis }
                    />
                </div>
                {(ladoFlag) &&
                <div className={`col-md-12 col-lg-${ inputSpace } ps-1 pe-1`}>
                    <ReactSelectSingleInput
                        formFieldStatus={ formFields['ladoAplicacion'].status }
                        label={(first) ? "Lado" : ""}
                        name="ladoAplicacion"
                        onChange={ handleInputLadoAplicacionChange }
                        required={true}
                        requiredIndicator={ (first) ? true : false }
                        setFormFieldValidation={ setFormFieldValidation }
                        showLabel={ (first) ? true : false }
                        value={ladoAplicacion}
                        values={lados}
                    />
                </div>
                }
                {(reaccionFlag) && 
                <div className={`col-md-12 col-lg-${ inputSpace } ps-1 pe-1`}>
                    <ReactSelectSingleInput
                        formFieldStatus={ formFields['reaccion'].status }
                        label={(first) ? "Reacción" : ""}
                        name="reaccion"
                        onChange={ handleInputReaccionChange }
                        required={false}
                        requiredIndicator={ false }
                        setFormFieldValidation={ setFormFieldValidation }
                        showLabel={ (first) ? true : false }
                        value={reaccion}
                        values={ ['Leve', 'Grande', 'Generalizada'] }
                    />
                </div>
                }
                <div className={`col-md-12 col-lg-${ inputSpace } ps-1 pe-1`}>
                    <ReactSelectSingleKeyInput
                        formFieldStatus={ formFields['realizado'].status }
                        label={(first) ? "Estatus" : ""}
                        name="realizado"
                        onChange={ handleInputRealizadoChange }
                        required={true}
                        requiredIndicator={ (first) ? true : false }
                        setFormFieldValidation={ setFormFieldValidation }
                        showLabel={ (first) ? true : false }
                        value={String(realizado)}
                        values={[{ value: 'false', label: 'Pendiente' }, { value: 'true', label: 'Aplicada' }]}
                    />
                </div>
                <div className="col-md-12 col-lg-1">
                    {(last) &&
                    <>
                        {(first) && <label>&nbsp;</label>}
                        <div className={styles.closeBtnContainer}>
                            <div>
                                <IconButton
                                    icon="Trash3"
                                    iconColor={colors.DANGER}
                                    marginTop={false}
                                    onClick={() => removeElement(element.id)}
                                    type="button"
                                    size={22}
                                />
                            </div>
                        </div>
                    </>
                    }
                </div>
            </div>
        </>
    )
}

AplicacionForm.propTypes = {
  element: PropTypes.any.isRequired,
  first: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  ladoFlag: PropTypes.bool,
  lados: PropTypes.array,
  last: PropTypes.bool.isRequired,
  reaccionFlag: PropTypes.bool,
  removeElement: PropTypes.func.isRequired,
  tipo: PropTypes.string,
  updateElement: PropTypes.func.isRequired,
}