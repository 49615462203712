import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'react-bootstrap-icons';

import { usePacienteInmunoterapiaProxima } from '../hooks/usePacienteInmunoterapiaProxima';
import { usePacienteInmunoterapiaFinalizar } from '../hooks/usePacienteInmunoterapiaFinalizar';
import { usePacienteInmunoterapiaEliminar } from '../hooks/usePacienteInmunoterapiaEliminar';

import { Empty, Error, IconButton, Loader } from '../../../components';

import { InmunoterapiaNueva } from './InmunoterapiaNueva';

import styles from '../PacienteConsulta.module.css';
import { colors } from '../../../types/types';
import { InmunoterapiaActiva } from './InmunoterapiaActiva';

export const InmunoterapiaProxima = ({ id }) => {

    const { pacienteInmunoterapiaProximaQuery: { isLoading, isError, data: inmunoterapias } } = usePacienteInmunoterapiaProxima(id);

    const { finishInmunoterapiaMutation: { mutate: finishInmunoterapia } } = usePacienteInmunoterapiaFinalizar(id);

    const { deleteInmunoterapiaMutation: { mutate: deleteInmunoterapia } } = usePacienteInmunoterapiaEliminar(id);

    const [nuevaStatus, setNuevaStatus] = useState(false);

    const [nuevaData, setNuevaData] = useState(null);

    const [tab, setTab] = useState(1);

    const handleCrearNueva = () => {
        setNuevaStatus(true);
        setTab(2);
    }

    const handleCancelarNueva = () => {
        setNuevaData(null);
        setNuevaStatus(false);
        setTab(1);
    }

    const handleFinalizar = (e, id) => {
        e.stopPropagation();
        finishInmunoterapia({ id, setNuevaData, setNuevaStatus, setTab, inmunoterapias: inmunoterapias.length });
    }

    const handleEliminar = (e, id) => {
        e.stopPropagation();
        deleteInmunoterapia(id);
        setTab(1);
    }

    return (
        <>
            {(isLoading)
                ? <Loader />
                : <>
                    {(isError)
                        ? <Error />
                        : <>
                            {(inmunoterapias.length === 2 || (inmunoterapias.length === 1 && nuevaStatus))
                                ? <div  className={styles.tabsContainerInmuno + " mt-3"}>
                                    <div className={"row"}>
                                        <div className="col p-0 text-center">
                                            <div className={ (tab === 1) ? styles.tabActiveInmuno : styles.tabInmuno } onClick={() => setTab(1)}>
                                                <span className={styles.tabInmunoDropdownText}>Frasco { inmunoterapias[0].identificador }</span>
                                                <div className={ (tab === 1) ? "dropdown " + styles.textTabActiveInmuno : "dropdown " + styles.textTabInmuno }>
                                                    <button className={"btn btn-secondary dropdown-toggle " + styles.tabInmunoDropdown} type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={(e) => e.stopPropagation()}>
                                                        <Icon.ThreeDotsVertical />
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li className={styles.dropdownItem} onClick={(e) => handleFinalizar(e, inmunoterapias[0]._id)}><div className="dropdown-item">Finalizar</div></li>
                                                        <li className={styles.dropdownItem} onClick={(e) => handleEliminar(e, inmunoterapias[0]._id)}><div className="dropdown-item">Eliminar</div></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {(typeof inmunoterapias[1] !== 'undefined') &&
                                        <div className="col p-0 text-center">
                                            <div className={ (tab === 2) ? styles.tabActiveInmuno : styles.tabInmuno } onClick={() => setTab(2)}>
                                                <span className={styles.tabInmunoDropdownText}>Frasco { inmunoterapias[1].identificador }</span>
                                                <div className={ (tab === 2) ? "dropdown " + styles.textTabActiveInmuno : "dropdown " + styles.textTabInmuno }>
                                                    <button className={"btn btn-secondary dropdown-toggle " + styles.tabInmunoDropdown} type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={(e) => e.stopPropagation()}>
                                                        <Icon.ThreeDotsVertical />
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li className={styles.dropdownItem} onClick={(e) => handleFinalizar(e, inmunoterapias[1]._id)}><div className="dropdown-item">Finalizar</div></li>
                                                        <li className={styles.dropdownItem} onClick={(e) => handleEliminar(e, inmunoterapias[1]._id)}><div className="dropdown-item">Eliminar</div></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {(nuevaStatus) &&
                                        <div className="col p-0 text-center">
                                            <div className={ (tab === 2) ? styles.tabActiveInmuno : styles.tabInmuno } onClick={() => setTab(2)}>
                                                <span className={styles.tabInmunoDropdownText}>Nueva</span>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                                : <div className="row">
                                    <div className="col">
                                        <div className={styles.singleTopInmunoterapias}>
                                            <span className={styles.tabsSubHeader}>{(nuevaStatus) ? 'Frasco (Nuevo)' : 'Frasco'}</span>
                                            {(!nuevaStatus && inmunoterapias.length < 2) &&
                                            <div className={styles.singleTopInmunoterapiasOptions}>
                                                <IconButton
                                                    icon="PlusCircle"
                                                    iconColor={colors.PRIMARY}
                                                    marginTop={false}
                                                    onClick={handleCrearNueva}
                                                    padding={false}
                                                    size={26}
                                                    type="button"
                                                />
                                                {(inmunoterapias.length === 1) &&
                                                <div className="dropdown ">
                                                    <button className={"btn btn-secondary dropdown-toggle " + styles.tabInmunoDropdown + " " + styles.singleTopInmunoterapiasOptionsText} type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={(e) => e.stopPropagation()}>
                                                        <Icon.ThreeDotsVertical />
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li className={styles.dropdownItem} onClick={(e) => handleFinalizar(e, inmunoterapias[0]._id)}><div className="dropdown-item">Finalizar</div></li>
                                                        <li className={styles.dropdownItem} onClick={(e) => handleEliminar(e, inmunoterapias[0]._id)}><div className="dropdown-item">Eliminar</div></li>
                                                    </ul>
                                                </div>
                                                }
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {(inmunoterapias.length === 0 && !nuevaStatus)
                                ? <Empty />
                                : <>
                                    {(typeof inmunoterapias[0] !== 'undefined' && tab === 1) &&
                                        <InmunoterapiaActiva inmunoterapia={inmunoterapias[0]} paciente={id} />
                                    }
                                    {(typeof inmunoterapias[1] !== 'undefined' && tab === 2) &&
                                        <InmunoterapiaActiva inmunoterapia={inmunoterapias[1]} paciente={id} />
                                    }
                                    {(nuevaStatus && ((typeof inmunoterapias[0] !== 'undefined' && tab === 2) || typeof inmunoterapias[0] === 'undefined')) &&
                                        <InmunoterapiaNueva handleCancelar={handleCancelarNueva} id={id} data={nuevaData} />
                                    }
                                </>
                            }
                        </>
                    }
                </>
            }
        </>
    )
}

InmunoterapiaProxima.propTypes = {
    id: PropTypes.string.isRequired,
}