import { inmunetApi } from '../../../api';

export const getAgendaInmunoterapia = async(fecha) => {

    try {

        const params = new URLSearchParams();
        if (fecha !== '--') {
            params.append('fecha', `${fecha}`);
        }

        const { data } = await inmunetApi.get(`/agendaInmunoterapia/index`, {
            params,
        });

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};