import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { posponerCita } from "../../CitaPaciente/services/cita.service";

import { successNotification, dangerNotification } from '../../../../redux/slices/ui';

export const useCitaPosponer = (fecha) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const posponerCitaMutation = useMutation({
        mutationFn: posponerCita,
        onSuccess: ({ inactivoFlag, horarioFlag, empalmadaFlag, cita }, variables, context) => {
            if(inactivoFlag){
                variables.onInactivo();
            }else if(horarioFlag){
                variables.onHorario();
            }else if(empalmadaFlag){
                variables.onEmpalmada();
            }else{
                queryClient.setQueryData(['agendaInmunoterapia', { fecha }], oldData => {
                    return {
                      ...oldData,
                      inmunoterapias: oldData.inmunoterapias.map(citaAux => {
                        if(String(citaAux._id) === String(cita._id)){
                            return {
                                ...citaAux,
                                dia: cita.dia,
                                diaSinFormato: cita.diaSinFormato,
                                hora: cita.hora,
                                horaSinFormato: cita.horaSinFormato,
                                etiqueta: cita.etiqueta,
                                etiquetaColor: cita.etiquetaColor
                            }
                        }else{
                            return citaAux;
                        }
                      })
                    };
                  });
                variables.onComplete();
                variables.onSuccess();
                dispatch(successNotification('Cita pospuesta'));
            }

        },
        onError: (error, variables, context) => {
            variables.onComplete();
            dispatch(dangerNotification(error.message));
            // dispatch(dangerNotification(error.msg.message));
        }
    });

    return {
        posponerCitaMutation,
    }
}