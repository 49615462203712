import { useState } from 'react';
import PropTypes from 'prop-types';

import { useCitaPosponer } from './hooks/useCitaPosponer';

import { useForm, useFormValidation } from '../../../hooks';

import { PrimaryButton, LightButton, DateInput, TimeInput } from '../../../components';

import styles from '../Modals.module.css';

export const PosponerCitaAgenda = ({ paciente: pacienteAux, cita = null, fecha: fechaAux, hide }) => {

    const [inactivoFlag, setInactivoFlag] = useState(false);
    const [horarioFlag, setHorarioFlag] = useState(false);
    const [empalmadaFlag, setEmpalmadaFlag] = useState(false);

    const activeInactivoFlag = () => {
        setInactivoFlag(true);
    }

    const activeHorarioFlag = () => {
        setHorarioFlag(true);
    }

    const activeEmpalmadaFlag = () => {
        setEmpalmadaFlag(true);
    }

    const { posponerCitaMutation: { mutate: posponerCita } } = useCitaPosponer(fechaAux);

    const { paciente, fecha, hora, onInputChange, onResetForm } = useForm({ paciente: pacienteAux, fecha: (cita !== null) ? cita.diaSinFormato : '', hora: (cita !== null) ? cita.horaSinFormato : '' });

    const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation, onResetValidation } = useFormValidation([{field: 'fecha', default: (cita !== undefined) ? true : false}, {field: 'hora', default: (cita !== undefined) ? true : false}]);

    const handleInputChange = (e) => {
        setInactivoFlag(false);
        setHorarioFlag(false);
        setEmpalmadaFlag(false);
        onInputChange(e);
    }

    const handleSuccessSubmit = () => {
        onResetForm();
        onResetValidation();
    }

    const onSubmit = async ( event ) => {
        event.preventDefault();

        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
            posponerCita({ id: cita._id, data: { paciente, fecha, hora, inactivoConfirmacion: inactivoFlag, horarioConfirmacion: horarioFlag, empalmadaConfirmacion: empalmadaFlag }, onSuccess: handleSuccessSubmit, onComplete: hide, onInactivo: activeInactivoFlag, onHorario: activeHorarioFlag, onEmpalmada: activeEmpalmadaFlag});
        }else{
            startFormValidation();
        }

    }

    return (
        <>
            <div>
                <form onSubmit={ onSubmit }>
                    <div className="row mb-3">
                        <div className="col">
                            <div className={styles.modalHeader}>Posponer cita</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <DateInput
                                formFieldStatus={ formFields['fecha'].status }
                                label="Fecha"
                                name="fecha"
                                onChange={ handleInputChange }
                                required={ true }
                                requiredIndicator={ true }
                                setFormFieldValidation={ setFormFieldValidation }
                                value={ fecha }
                            />
                        </div>
                        <div className="col-6">
                            <TimeInput
                                formFieldStatus={ formFields['hora'].status }
                                label="Hora"
                                name="hora"
                                onChange={ handleInputChange }
                                required={ true }
                                requiredIndicator={ true }
                                setFormFieldValidation={ setFormFieldValidation }
                                value={ hora }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {(inactivoFlag) && <p className={styles.helperErrorText}>La fecha seleccionada esta marcada como día inactivo en la configuración del consultorio. ¿Deseas continuar igualmente?</p>}
                            {(horarioFlag) && <p className={styles.helperErrorText}>El periodo de tiempo seleccionado esta fuera del horario configurado para el consultorio. ¿Deseas continuar igualmente?</p>}
                            {(empalmadaFlag) && <p className={styles.helperErrorText}>El periodo de tiempo seleccionado se empalma con otra cita previamente agendada. ¿Deseas continuar igualmente?</p>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <LightButton
                                block={true}
                                icon="X"
                                marginTop={false}
                                onClick={ hide }
                                text="Cancelar"
                                type="button"
                            />
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6'>
                            <PrimaryButton
                                block={true}
                                disabled={formValidationStatus === 'checking'}
                                icon="Check"
                                marginTop={false}
                                text={(inactivoFlag || horarioFlag || empalmadaFlag) ? "Confirmar" : "Posponer"}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

PosponerCitaAgenda.propTypes = {
  cita: PropTypes.object,
  fecha: PropTypes.string.isRequired,
  hide: PropTypes.func.isRequired,
  paciente: PropTypes.string.isRequired,
}