import { createSlice } from '@reduxjs/toolkit';

export const consultaSlice = createSlice({
    name: 'consulta',
    initialState: {
        // id: {
        //     paciente: '',
        //     datosGenerales: {
        //         motivo: '',
        //         peea: '',
        //         exploracionFisica: {
        //             habitusExterior: '',
        //             cabezaYCuello: '',
        //             torax: '',
        //             abdomen: '',
        //             extremidades: '',
        //             piel: '',
        //             genitales: '',
        //             otro: ''
        //         },
        //         signosVitales: {
        //             fc: '',
        //             fr: '',
        //             ta: '',
        //             t: '',
        //             saO2: '',
        //             peso: '',
        //             talla: '',
        //             imc: '0',
        //             fem: ''
        //         },
        //     },
        //          diagnostico: [],
        //         medicamentos: [],
        //         pronostico: '',
        //         proximaCita: {
        //             fecha: '',
        //             hora: '',
        //             motivo: '',
        //             duracion: '',
        //             comentario: ''
        //         },
        //         comentarios: ''
        // },
    },
    reducers: {
        initPaciente: (state, { payload }) => {
            if (typeof state[payload] === 'undefined') {
                state[payload] = {
                    paciente: payload,
                    datosGenerales: {
                        motivo: '',
                        peea: '',
                        exploracionFisica: {
                            habitusExterior: '',
                            cabezaYCuello: '',
                            torax: '',
                            abdomen: '',
                            extremidades: '',
                            piel: '',
                            genitales: '',
                            otro: ''
                        },
                        signosVitales: {
                            fc: '',
                            fr: '',
                            ta: '',
                            t: '',
                            saO2: '',
                            peso: '',
                            talla: '',
                            imc: '0',
                            fem: ''
                        },
                    },
                    proximaCita: {
                        fecha: '',
                        hora: '',
                        motivo: '',
                        duracion: '',
                        comentario: ''
                    },
                    pronostico: '',
                    comentarios: ''
                };
            }
        },
        clearPaciente: (state, { payload }) => {
            delete state[payload];
        },
        setMotivo: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.motivo = payload.motivo;
            }
        },
        clearMotivo: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.motivo = '';
            }
        },
        setPeea: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.peea = payload.peea;
            }
        },
        clearPeea: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.peea = '';
            }
        },
        setHabitusExterior: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.exploracionFisica.habitusExterior = payload.habitusExterior;
            }
        },
        clearHabitusExterior: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.exploracionFisica.habitusExterior = '';
            }
        },
        setCabezaYCuello: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.exploracionFisica.cabezaYCuello = payload.cabezaYCuello;
            }
        },
        clearCabezaYCuello: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.exploracionFisica.cabezaYCuello = '';
            }
        },
        setTorax: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.exploracionFisica.torax = payload.torax;
            }
        },
        clearTorax: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.exploracionFisica.torax = '';
            }
        },
        setAbdomen: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.exploracionFisica.abdomen = payload.abdomen;
            }
        },
        clearAbdomen: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.exploracionFisica.abdomen = '';
            }
        },
        setExtremidades: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.exploracionFisica.extremidades = payload.extremidades;
            }
        },
        clearExtremidades: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.exploracionFisica.extremidades = '';
            }
        },
        setPiel: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.exploracionFisica.piel = payload.piel;
            }
        },
        clearPiel: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.exploracionFisica.piel = '';
            }
        },
        setGenitales: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.exploracionFisica.genitales = payload.genitales;
            }
        },
        clearGenitales: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.exploracionFisica.genitales = '';
            }
        },
        setOtro: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.exploracionFisica.otro = payload.otro;
            }
        },
        clearOtro: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.exploracionFisica.otro = '';
            }
        },
        setFc: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.signosVitales.fc = payload.fc;
            }
        },
        clearFc: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.signosVitales.fc = '';
            }
        },
        setFr: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.signosVitales.fr = payload.fr;
            }
        },
        clearFr: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.signosVitales.fr = '';
            }
        },
        setTa: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.signosVitales.ta = payload.ta;
            }
        },
        clearTa: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.signosVitales.ta = '';
            }
        },
        setT: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.signosVitales.t = payload.t;
            }
        },
        clearT: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.signosVitales.t = '';
            }
        },
        setSaO2: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.signosVitales.sa02 = payload.sa02;
            }
        },
        clearSaO2: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.signosVitales.sa02 = '';
            }
        },
        setPeso: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.signosVitales.peso = payload.peso;
            }
        },
        clearPeso: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.signosVitales.peso = '';
            }
        },
        setTalla: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.signosVitales.talla = payload.talla;
            }
        },
        clearTalla: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.signosVitales.talla = '';
            }
        },
        setImc: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.signosVitales.imc = payload.imc;
            }
        },
        clearImc: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.signosVitales.imc = '';
            }
        },
        setFem: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].datosGenerales.signosVitales.fem = payload.fem;
            }
        },
        clearFem: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].datosGenerales.signosVitales.fem = '';
            }
        },
        setCitaFecha: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].proximaCita.fecha = payload.fecha;
            }
        },
        clearCitaFecha: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].proximaCita.fecha = '';
            }
        },
        setCitaHora: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].proximaCita.hora = payload.hora;
            }
        },
        clearCitaHora: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].proximaCita.hora = '';
            }
        },
        setCitaMotivo: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].proximaCita.motivo = payload.motivo;
            }
        },
        clearCitaMotivo: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].proximaCita.motivo = '';
            }
        },
        setCitaDuracion: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].proximaCita.duracion = payload.duracion;
            }
        },
        clearCitaDuracion: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].proximaCita.duracion = '';
            }
        },
        setCitaComentario: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].proximaCita.comentario = payload.comentario;
            }
        },
        clearCitaComentario: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].proximaCita.comentario = '';
            }
        },
        setPronostico: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].pronostico = payload.pronostico;
            }
        },
        clearPronostico: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].pronostico = '';
            }
        },
        setComentarios: (state, { payload }) => {
            if (typeof state[payload.paciente] !== 'undefined') {
                state[payload.paciente].comentarios = payload.comentarios;
            }
        },
        clearComentarios: (state, { payload }) => {
            if (typeof state[payload] !== 'undefined') {
                state[payload].comentarios = '';
            }
        },
    }
});


// Action creators are generated for each case reducer function
export const {
    initPaciente,
    clearPaciente,
    setMotivo,
    clearMotivo,
    setPeea,
    clearPeea,
    setHabitusExterior,
    clearHabitusExterior,
    setCabezaYCuello,
    clearCabezaYCuello,
    setTorax,
    clearTorax,
    setAbdomen,
    clearAbdomen,
    setExtremidades,
    clearExtremidades,
    setPiel,
    clearPiel,
    setGenitales,
    clearGenitales,
    setOtro,
    clearOtro,
    setFc,
    clearFc,
    setFr,
    clearFr,
    setTa,
    clearTa,
    setT,
    clearT,
    setSaO2,
    clearSaO2,
    setPeso,
    clearPeso,
    setTalla,
    clearTalla,
    setImc,
    clearImc,
    setFem,
    clearFem,
    setCitaFecha,
    clearCitaFecha,
    setCitaHora,
    clearCitaHora,
    setCitaMotivo,
    clearCitaMotivo,
    setCitaDuracion,
    clearCitaDuracion,
    setCitaComentario,
    clearCitaComentario,
    setPronostico,
    clearPronostico,
    setComentarios,
    clearComentarios,
} = consultaSlice.actions;