import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { eliminarCita } from "../services/citas.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const useCitaEliminar = (fecha) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const eliminarCitaMutation = useMutation({
        mutationFn: eliminarCita,
        onSuccess: (cita, variables, context) => {
            queryClient.setQueryData(['agendaInmunoterapia', { fecha }], oldData => {
                return {
                  ...oldData,
                  inmunoterapias: oldData.inmunoterapias.filter(citaAux => String(citaAux._id) !== String(cita))
                };
              });
            dispatch(successNotification('Cita eliminada'));
        },
        onError: (error, variables, context) => {
            dispatch(dangerNotification(error.message));
            // dispatch(dangerNotification(error.msg.message));
        }
    });

    return {
        eliminarCitaMutation,
    }
}