import { inmunetApi } from '../../../api';

export const getInmunoterapias = async(id) => {

    try {

        const { data } = await inmunetApi.get(`/inmunoterapia/index/${id}`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};