import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as Icon from 'react-bootstrap-icons';

import { useInmunoterapia } from './hooks/useInmunoterapia';
import { useCitaConfirmar } from './hooks/useCitaConfirmar';
import { useCitaAtender } from './hooks/useCitaAtender';
import { useCitaCancelar } from './hooks/useCitaCancelar';
import { useCitaNoPresentar } from './hooks/useCitaNoPresentar';
import { useCitaEliminar } from './hooks/useCitaEliminar';

import { Empty, Error, Loader, ModalContainer } from '../../components';
import { PosponerCitaAgenda } from '../Modals/PosponerCitaAgenda';

import { formatDate } from '../../helpers/formatDate';

import styles from './Inmunoterapia.module.css';

export const Inmunoterapia = () => {

  const { day, month, year } = useSelector(state => state.ui.fecha);

  const [posponerCitaModal, setPosponerCitaModal] = useState(null);

  const { agendaInmunoterapiaQuery: { isLoading, isFetching , isError, data: { inmunoterapias } } } = useInmunoterapia(`${year}-${month}-${day}`);

  const { confirmarCitaMutation: { mutate: confirmarCita } } = useCitaConfirmar(`${year}-${month}-${day}`);

  const { atenderCitaMutation: { mutate: atenderCita } } = useCitaAtender(`${year}-${month}-${day}`);

  const { cancelarCitaMutation: { mutate: cancelarCita } } = useCitaCancelar(`${year}-${month}-${day}`);

  const { noPresentarCitaMutation: { mutate: noPresentarCita } } = useCitaNoPresentar(`${year}-${month}-${day}`);

  const { eliminarCitaMutation: { mutate: eliminarCita } } = useCitaEliminar(`${year}-${month}-${day}`);

  const handlePosponerCita = (cita) => {
    setPosponerCitaModal(cita);
  }

  const handleCancelarPosponerCita = () => {
    setPosponerCitaModal(null);
  }

  const handleConfirmarCita = (id) => {
    confirmarCita({ id: id});
  }

  const handleAtenderCita = (id) => {
    atenderCita({ id: id});
  }

  const handleCancelarCita = (id) => {
    cancelarCita({ id: id});
  }

  const handleNoPresentarCita = (id) => {
    noPresentarCita({ id: id});
  }

  const handleEliminarCita = (id) => {
    eliminarCita({ id: id});
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <div className={styles.agendaHeader}>{formatDate(day, month, year)}</div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {(isLoading)
            ? <Loader />
            : <>
              {(isError)
                ? <Error />
                : <>
                  <div className={styles.panel + " " + styles.panelNoHeader}>
                    <div className="row">
                      <div className="col">
                        <div className="table-responsive">
                          <table className="table align-middle">
                            <thead>
                              <tr>
                                <th scope="col">Paciente</th>
                                <th scope="col">Motivo</th>
                                <th scope="col">ITE</th>
                                <th scope="col">Diagnóstico</th>
                                <th scope="col">Frasco #</th>
                                <th scope="col">Conc. / Vol. alérgeno</th>
                                <th scope="col">Alérgenos / Vial</th>
                                <th scope="col">Vol. Total</th>
                                <th scope="col">Diluyente</th>
                                <th scope="col">Última Aplicación</th>
                                <th scope="col">Estatus</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody className="table-group-divider">
                            {(isFetching)
                            ? <tr className='noBorder noHover noPointer'><td colSpan={12}><Loader /></td></tr>
                            : (inmunoterapias.length < 1)
                              ? <tr className='noBorder noHover noPointer'><td colSpan={12}><Empty /></td></tr>
                              : inmunoterapias.map(inmuno => <tr key={ inmuno._id }>
                                  <td>{ inmuno.paciente.nombre }</td>
                                  <td>{ inmuno.motivo.nombre }</td>
                                  <td>{ inmuno.inmunoterapia.tipo }</td>
                                  <td>-</td>
                                  <td>{ inmuno.inmunoterapia.identificador }</td>
                                  <td>{ inmuno.inmunoterapia.concentracion } ({ inmuno.inmunoterapia.volumenAlergeno })</td>
                                  <td>{ inmuno.inmunoterapia.alergenos.map(alerg => alerg.nombre).join(', ') }</td>
                                  <td>{ inmuno.inmunoterapia.volumenTotal } { inmuno.inmunoterapia.tipoDosis }</td>
                                  <td>{ (inmuno.inmunoterapia.tipo === 'Subcutánea' || inmuno.inmunoterapia.tipo === 'Sublingual') ? `${ inmuno.inmunoterapia.diluyente } ${ inmuno.inmunoterapia.tipoDosis }` : '-' }</td>
                                  <td>{ (inmuno.inmunoterapia.ultimaAplicacionDosis !== null) ? `${ inmuno.inmunoterapia.ultimaAplicacionDosis } ${ inmuno.inmunoterapia.tipoDosis } - ${ inmuno.inmunoterapia.ultimaAplicacionFechaFormato }` : '-' }</td>
                                  <td><span className="badge" style={{backgroundColor: inmuno.etiquetaColor}}>{ inmuno.etiqueta }</span></td>
                                  <td>
                                    <div className='d-flex justify-content-end align-items-center'>
                                      <div className={"dropdown " + styles.tableDropdown}>
                                        <button className={"btn dropdown-toggle dropdown-toggle-no-indicator " + styles.tableDropdownButton} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                          <Icon.ThreeDotsVertical color='#666666' size={20} />
                                        </button>
                                        <ul className="dropdown-menu">
                                          <li><div className={"dropdown-item " + styles.tableDropdownItem} onClick={() => handlePosponerCita(inmuno)}>Posponer</div></li>
                                          {(!inmuno.confirmada)
                                          ? <li><div className={"dropdown-item " + styles.tableDropdownItem} onClick={() => handleConfirmarCita(inmuno._id)}>Confirmar</div></li>
                                          : <>
                                            <li><div className={"dropdown-item " + styles.tableDropdownItem} onClick={() => handleAtenderCita(inmuno._id)}>Atender</div></li>
                                            <li><div className={"dropdown-item " + styles.tableDropdownItem} onClick={() => handleNoPresentarCita(inmuno._id)}>No presentar</div></li>
                                          </>
                                          }
                                          <li><div className={"dropdown-item " + styles.tableDropdownItem} onClick={() => handleCancelarCita(inmuno._id)}>Cancelar</div></li>
                                          <li><div className={"dropdown-item " + styles.tableDropdownItem} onClick={() => handleEliminarCita(inmuno._id)}>Eliminar</div></li>
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                </tr>) }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            </>
          }
        </div>
      </div>
      {(posponerCitaModal != null) &&
      <ModalContainer
        state={ posponerCitaModal != null }
        hide={ handleCancelarPosponerCita }
        content={ <PosponerCitaAgenda hide={ handleCancelarPosponerCita } paciente={posponerCitaModal.paciente._id} cita={posponerCitaModal} fecha={`${year}-${month}-${day}`} posponer={true} /> }
      />
      }
    </>
  )
}
