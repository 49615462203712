import PropTypes from 'prop-types';

import { LightButton, TextInputPreview } from '../../../components';

import styles from '../Modals.module.css';

export const DetalleInmunoterapiaModal = ({ data, hide }) => {
  return (
    <div>
        <div className="row mb-3">
            <div className="col">
                <div className={styles.modalHeader}>Detalles</div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 col-lg-3">
                <TextInputPreview
                    label="Inmunoterapia"
                    name="tipo"
                    value={(typeof data.tipo !== 'undefined') ? data.tipo : ''}
                />
            </div>
            <div className="col-md-12 col-lg-9">
                <TextInputPreview
                    label="Alérgenos"
                    name="alergenos"
                    value={(typeof data.alergenos !== 'undefined') ? data.alergenos.map(aler => aler.nombre).join(', ') : ''}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 col-lg-3">
                <TextInputPreview
                    label="Fase"
                    name="fase"
                    value={(typeof data.fase !== 'undefined') ? data.fase : ''}
                />
            </div>
            <div className="col-md-12 col-lg-3">
                <TextInputPreview
                    label="Frasco"
                    name="frasco"
                    value={(typeof data.identificador !== 'undefined') ? data.identificador : ''}
                />
            </div>
            <div className="col-md-12 col-lg-3">
                <TextInputPreview
                    label="Concentración"
                    name="concentracion"
                    value={(typeof data.concentracion !== 'undefined') ? data.concentracion : ''}
                />
            </div>
            <div className="col-md-12 col-lg-3">
                <TextInputPreview
                    label={`Vol. alérgeno (${ (typeof data.tipoDosis !== 'undefined') ? data.tipoDosis : '' })`}
                    name="volumenAlergeno"
                    value={(typeof data.volumenAlergeno !== 'undefined') ? String(data.volumenAlergeno) : ''}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 col-lg-3">
                <TextInputPreview
                    label="Última apicación"
                    name="ultimaAplicacionFechaFormato"
                    value={(typeof data.ultimaAplicacionFechaFormato !== 'undefined') ? data.ultimaAplicacionFechaFormato : ''}
                />
            </div>
            <div className="col-md-12 col-lg-3">
                <TextInputPreview
                    label={`Dosis (${ (typeof data.tipoDosis !== 'undefined') ? data.tipoDosis : '' })`}
                    name="ultimaAplicacionDosis"
                    value={(typeof data.ultimaAplicacionDosis !== 'undefined') ? String(data.ultimaAplicacionDosis) : ''}
                />
            </div>
            <div className="col-md-12 col-lg-3">
                <TextInputPreview
                    label={(typeof data.sitioAplicacion !== 'undefined') ? data.sitioAplicacion : ''}
                    name="ladoAplicacion"
                    value={(typeof data.ultimaAplicacionLadoAplicacion !== 'undefined') ? data.ultimaAplicacionLadoAplicacion : ''}
                />
            </div>
            <div className="col-md-12 col-lg-3">
                <TextInputPreview
                    label="Reacción"
                    name="reacción"
                    value={(typeof data.ultimaAplicacionReaccion !== 'undefined' && data.ultimaAplicacionReaccion !== '') ? data.ultimaAplicacionReaccion : '-'}
                />
            </div>
        </div>
        <div className="row">
            <div className="col">
                <TextInputPreview
                    label="Comentarios"
                    name="comentarios"
                    value={(typeof data.comentarios !== 'undefined' && data.comentarios !== '') ? data.comentarios : '-'}
                />
            </div>
        </div>
        <div className="row">
            <div className='col-xs-12 col-sm-12 col-md-6'></div>
            <div className="col-xs-12 col-sm-12 col-md-6">
                <LightButton
                    block={true}
                    icon="X"
                    marginTop={false}
                    onClick={ hide }
                    text="Cerrar"
                    type="button"
                />
            </div>
        </div>
    </div>
  )
}

DetalleInmunoterapiaModal.propTypes = {
    data: PropTypes.object.isRequired,
    hide: PropTypes.func.isRequired
}