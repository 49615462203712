import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const NumberInput = ({ disabled = false, formFieldStatus='checked', label, min = null, max = null, name, onChange, placeholder, readonly = false, required = false, requiredIndicator = false, setFormFieldValidation, showLabel = true, step = 1, value }) => {

    const [inputValidation, setInputValidation] = useState({ isValid: null, msg: null });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputValidation = useCallback(() => {
        if(value !== '' && value > -1 && (min === null || value >= min) && (max === null || value <= max)){
            setInputValidation({ isValid: true, msg: null });
            if(setFormFieldValidation) setFormFieldValidation(name, true);
        }else{
            if(required){
                setInputValidation({ isValid: false, msg: label + ' no válidos' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
            }else{
                setInputValidation({ isValid: true, msg: null });
                if(setFormFieldValidation) setFormFieldValidation(name, true);
            }
        }
    })

    useEffect(() => {
        handleInputValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        if(formFieldStatus === 'checking') handleInputValidation();
    }, [formFieldStatus, handleInputValidation]);
  
    return (
        <>
        {(showLabel) && <label htmlFor={ name } className={styles.customInputLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>}
        <input type="number" className={"form-control " + styles.customInputBasic + ((inputValidation.isValid != null && !inputValidation.isValid) ? " " + styles.customErrorInputBasic : "")} placeholder={ placeholder } name={ name } value={ value } onChange={ onChange } required={required} min={(min !== null) ? min : 0} max={(max !== null) ? max : undefined} autoComplete="off" step={step} readOnly={readonly} disabled={disabled} />
        <div className={styles.helperErrorText + ((inputValidation.isValid != null && !inputValidation.isValid) ? "" : " d-none" )}>{(inputValidation.isValid != null && !inputValidation.isValid) && inputValidation.msg}</div>
        </>
    )
}

NumberInput.propTypes = {
  disabled: PropTypes.bool,
  formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  requiredIndicator: PropTypes.bool,
  setFormFieldValidation: PropTypes.func,
  showLabel: PropTypes.bool,
  step: PropTypes.number,
  value: PropTypes.any,
}