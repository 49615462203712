import { inmunetApi } from '../../../api';

export const createPaciente = async({ data: paciente }) => {

    try {

        let formData = new FormData();
        Object.keys(paciente).forEach(field => {
            formData.append(field, paciente[field]);
        });

        const { data } = await inmunetApi.post(`/paciente`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al crear paciente';

        return {
            modal: false,
            paciente: data.paciente
        };

    } catch (error) {

        if (error.response.data.modal) {
            return {
                modal: true,
                paciente: error.response.data.paciente
            };
        } else {
            if (error.response.data.msg) {
                throw new Error(error.response.data.msg);
            } else {
                throw new Error('Error al crear paciente');
            }
        }

    }

};