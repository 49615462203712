import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import styles from './FormFields.module.css';

export const ReactSelectSingleInput = ({ formFieldStatus='checked', label = '', name, onChange, required = false, requiredIndicator = false, setFormFieldValidation, showLabel = true, value, values}) => {

  const css = `
        .select__control--is-focused{
            border-color: #86b7fe !important;
            box-shadow: none !important;
        }
    `;

    const [inputValidation, setInputValidation] = useState({ isValid: null, msg: null });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputValidation = useCallback(() => {
        if(values.includes(value)){
            setInputValidation({ isValid: true, msg: null });
            if(setFormFieldValidation) setFormFieldValidation(name, true);

        }else{
            if(value !== ''){
                setInputValidation({ isValid: false, msg: 'Selección no válida' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
            }else{
                // setInputValidation({ isValid: null, msg: null });
                if(required){
                    setInputValidation({ isValid: false, msg: 'Selección no válida' });
                    if(setFormFieldValidation) setFormFieldValidation(name, false);
                }else{
                    setInputValidation({ isValid: true, msg: null });
                    if(setFormFieldValidation) setFormFieldValidation(name, true);
                }
            }
        }
    })

    const handleOnChange = (val) => {
        onChange({ target: { name: name, value: val.value } });
    }

    useEffect(() => {
        handleInputValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        if(values.includes(value)){
            setInputValidation({ isValid: true, msg: null });
            if(setFormFieldValidation) setFormFieldValidation(name, true);
        }else{
            if(value === '' || value === undefined){
                setInputValidation({ isValid: null, msg: null });
                if(setFormFieldValidation) setFormFieldValidation(name, null);
            }else{
                setInputValidation({ isValid: false, msg: 'Selección no válida' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(formFieldStatus === 'checking') handleInputValidation();
      }, [formFieldStatus, handleInputValidation]);
  
    return (
        <>
            <style>{ css }</style>
            {(showLabel) && <label htmlFor={ name } className={styles.customPreviewLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>}
            <Select
                className={"basic-multi-select " + styles.basicMultiSelect}
                classNamePrefix="select"
                defaultValue={[{ value: value, label: value }]}
                isClearable={false}
                isSearchable={false}
                name={name}
                onChange={handleOnChange}
                options={values.map(val => ({ value: val, label: val }))}
            />
            <div className={styles.helperErrorTextReactSelect + ((inputValidation.isValid != null && !inputValidation.isValid) ? "" : " d-none" )}>{(inputValidation.isValid != null && !inputValidation.isValid) && inputValidation.msg}</div>
        </>
    )
}

ReactSelectSingleInput.propTypes = {
    formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    requiredIndicator: PropTypes.bool,
    setFormFieldValidation: PropTypes.func,
    showLabel: PropTypes.bool,
    value: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
}