import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { finishPacienteInmunoterapia } from "../services/inmunoterapia.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacienteInmunoterapiaFinalizar = (id) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const finishInmunoterapiaMutation = useMutation({
        mutationFn: finishPacienteInmunoterapia,
        onSuccess: ({inmunoterapia, nueva, tipos, fases, sitiosFlag, sitios, ladoFlag, lados, reaccionFlag, frascos}, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['paciente/inmunoterapia/proxima', id] });
            queryClient.invalidateQueries({ queryKey: ['paciente/inmunoterapia/previa', id] });
            dispatch(successNotification('Inmunoterapia finalizada'));
            if(nueva){
                queryClient.setQueryData(['inmunoterapiaTipo'], oldData => {

                    return tipos;
                    
                });
                queryClient.setQueryData(['inmunoterapiaFase'], oldData => {

                    return fases;
                    
                });
                queryClient.setQueryData(['inmunoterapiaSitio'], oldData => {

                    return {
                        sitiosFlag,
                        sitios
                    };
                    
                });
                queryClient.setQueryData(['inmunoterapiaLado'], oldData => {

                    return {
                        ladoFlag,
                        lados
                    };
                    
                });
                queryClient.setQueryData(['inmunoterapiaReaccion'], oldData => {

                    return reaccionFlag;
                    
                });
                queryClient.setQueryData(['inmunoterapiaFrasco'], oldData => {

                    return frascos;
                    
                });
                variables.setNuevaData(nueva);
                variables.setNuevaStatus(true);
                variables.setTab((variables.inmunoterapias === 2) ? 2 : 1);
            }else{
                variables.setTab(1);
            }
        },
        onError: (error, variables, context) => {
            variables.onComplete();
            dispatch(dangerNotification(error.message));
            // dispatch(dangerNotification(error.msg.message));
        }
    });

    return {
        finishInmunoterapiaMutation,
    }
}