import { inmunetApi } from '../../../api';

export const createPacienteConsulta = async({ data: consulta, id }) => {

    try {

        const { data } = await inmunetApi.post(`/consulta/${id}`, consulta);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al registrar consulta';

        return {
            inactivoFlag: false,
            horarioFlag: false,
            empalmadaFlag: false,
            consulta: data.consulta
        };

    } catch (error) {

        if (error.response.data.inactivoFlag) {
            return {
                inactivoFlag: true,
                horarioFlag: false,
                empalmadaFlag: false,
                consulta: error.response.data.msg
            };
        } else if (error.response.data.horarioFlag) {
            return {
                inactivoFlag: false,
                horarioFlag: true,
                empalmadaFlag: false,
                consulta: error.response.data.msg
            };
        } else if (error.response.data.empalmadaFlag) {
            return {
                inactivoFlag: false,
                horarioFlag: false,
                empalmadaFlag: true,
                consulta: error.response.data.msg
            };
        } else {
            if (error.response.data.msg) {
                throw new Error(error.response.data.msg);
            } else {
                throw new Error('Error al registrar consulta');
            }
        }

    }

};