const colors = {

    PRIMARY: '#4D96D9',
    PRIMARY_LIGHT: '#BCD7F1',
    INPUT_FEEDBACK: '#4A4A4A',
    SUCCESS: '#7fae1b',
    DANGER: '#F34336',
    PANEL_BORDER: '#C7D4DD',
    CHART_COLOR_1: '#89AB3B',
    CHART_COLOR_2: '#4D96D9',
    HEADER: '#1F3C57',
    SUBHEADER: '#2E5A82',
    PANEL_HEADER: '#737D89',
    BG_COLOR_2: '#EDF4FB',

}

module.exports = {
    colors
}