import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        consultorio: {
            // _id: '',
            // nombre: ''
        },
        fecha: {
            day: String(new Date().getDate()).padStart(2, '0'),
            month: String(new Date().getMonth() + 1).padStart(2, '0'),
            year: new Date().getFullYear(),
        },
        notifications: [
            // {
            //     id: '', // moment().unix()
            //     content: '',
            //     type: '', // 'success', 'info', 'warning', 'danger'
            // }
        ],
        pacienteSearch: ''
    },
    reducers: {
        setConsultorio: (state, { payload }) => {
            state.consultorio = payload;
        },
        setDay: (state, { payload }) => {
            state.fecha = {
                ...state.fecha,
                day: payload
            };
        },
        setMonth: (state, { payload }) => {
            state.fecha = {
                ...state.fecha,
                month: payload
            };
        },
        setYear: (state, { payload }) => {
            state.fecha = {
                ...state.fecha,
                year: payload
            };
        },
        addNotification: (state, { payload }) => {
            state.notifications = [
                ...state.notifications,
                {
                    id: payload.id,
                    content: payload.content,
                    type: payload.type,
                }
            ];
        },
        removeNotification: (state, { payload }) => {
            state.notifications = state.notifications.filter(noty => noty.id !== payload);
        },
        clearNotifications: (state) => {
            state.notifications = [];
        },
        setPacienteSearch: (state, { payload }) => {
            state.pacienteSearch = payload;
        },
        clearPacienteSearch: (state) => {
            state.pacienteSearch = '';
        },
    }
});


// Action creators are generated for each case reducer function
export const { setConsultorio, setDay, setMonth, setYear, addNotification, removeNotification, clearNotifications, setPacienteSearch, clearPacienteSearch } = uiSlice.actions;