import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Calendar from 'react-calendar';

import { setDay, setMonth, setYear } from "../../redux/slices/ui";

import { colors } from '../../types/types';
import 'react-calendar/dist/Calendar.css';

const css = `
    .react-calendar {
        border: none;
    }
    .react-calendar__month-view__weekdays abbr {
        text-decoration: none !important; /* Evita que el navegador intente agregar el título */
        pointer-events: none; /* Evita que se muestren tooltips */
    }
    .react-calendar__navigation__label {
        pointer-events: none;
        cursor: default;
    }
    .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
        background-color: ${ colors.BG_COLOR_2 };
    }
    .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
        background-color: ${ colors.BG_COLOR_2 };
    }
    .react-calendar__tile--active {
        background: ${ colors.PRIMARY } !important;
        color: white;
    }
    .react-calendar__tile--now {
        background: white !important;
        color: black;
        border: 1px solid ${ colors.PRIMARY } !important;
        border-radius: 6px;
    }
    .react-calendar__tile--now:hover {
        background-color: ${ colors.BG_COLOR_2 } !important; /* Azul al pasar el cursor */
        color: black !important; /* Texto blanco */
    }
    .react-calendar__navigation__arrow {
        font-size: 24px;
        color: ${ colors.PRIMARY };
    }
`;

export const CalendarioSelector = () => {

    const dispatch = useDispatch();

    const [date, onChange] = useState(new Date());

    useEffect(() => {
        const dia = String(date.getDate()).padStart(2, "0");
        const mes = String(date.getMonth() + 1).padStart(2, "0");
        const año = String(date.getFullYear());
        dispatch(setDay(dia));
        dispatch(setMonth(mes));
        dispatch(setYear(año));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date])

    return (
        <>
            <style>{ css }</style>
            <div className='mb-3'>
                <Calendar
                    onChange={onChange}
                    value={date}
                    defaultView="month"
                    view="month"
                    next2Label={null}
                    prev2Label={null}
                    formatMonthYear={(locale, dateAux) => date.toLocaleDateString("es-MX", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                        }).replace(" de", "")
                    }
                />
            </div>
        </>
    )
}
