import { inmunetApi } from '../../../api';

export const getLaboratorio = async() => {

    try {

        const { data } = await inmunetApi.get(`/laboratorio`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.laboratorios;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const getPacienteLaboratorio = async(id) => {

    try {

        const { data } = await inmunetApi.get(`/paciente/laboratorio/${id}`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.laboratorio;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const createPacienteLaboratorio = async({ data: laboratorio, id }) => {

    try {

        let formData = new FormData();
        Object.keys(laboratorio).forEach(field => {
            if (field === 'archivos') {
                laboratorio[field].forEach((file, index) => {
                    formData.append(`archivos[${index}]`, laboratorio[field][index]);
                });
            } else {
                formData.append(field, laboratorio[field]);
            }
        });

        const { data } = await inmunetApi.post(`/paciente/laboratorio/${id}`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al crear laboratorio';

        return data.laboratorio;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error('Error al crear laboratorio');
        }

    }

};