import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { atenderCita } from "../services/citas.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const useCitaAtender = (fecha) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const atenderCitaMutation = useMutation({
        mutationFn: atenderCita,
        onSuccess: (cita, variables, context) => {
            queryClient.setQueryData(['agendaInmunoterapia', { fecha }], oldData => {
                return {
                  ...oldData,
                  inmunoterapias: oldData.inmunoterapias.map(citaAux => {
                    if(String(citaAux._id) === String(cita._id)){
                        return {
                            ...citaAux,
                            atendida: cita.atendida,
                            etiqueta: cita.etiqueta,
                            etiquetaColor: cita.etiquetaColor
                        }
                    }else{
                        return citaAux;
                    }
                  })
                };
              });
            dispatch(successNotification('Cita atendida'));
        },
        onError: (error, variables, context) => {
            dispatch(dangerNotification(error.message));
            // dispatch(dangerNotification(error.msg.message));
        }
    });

    return {
        atenderCitaMutation,
    }
}