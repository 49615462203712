import { inmunetApi } from '../../../api';

export const getPacienteCuestionarios = async(idPaciente) => {

    try {

        const { data } = await inmunetApi.get(`cuestionario/proximos/${ idPaciente }`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.proximosCuestionarios;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const getPacienteCuestionariosPasados = async(idPaciente) => {

    try {

        const { data } = await inmunetApi.get(`cuestionario/pasados/${ idPaciente }`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.pasadosCuestionarios;

    } catch (error) {

        throw new Error('Error al obtener los datos');

    }

};

export const toggleCuestionarioStatus = async({ id: idPaciente, noCuestionario, status }) => {

    try {

        let formData = new FormData();
        formData.append('numero_cuestionario', String(noCuestionario));

        let res = null;

        if (status === 'true') {

            res = await inmunetApi.post(`/cuestionario/${ idPaciente }`, { numeroCuestionario: noCuestionario });

        } else {

            res = await inmunetApi.put(`/cuestionario/desactivar/${ idPaciente }`, { numeroCuestionario: noCuestionario });

        }

        let { data } = res;
        if (!data.status) throw (data.msg) ? data.msg : 'Error al activar/desactivar cuestionario';

        return data.cuestionario;

    } catch (error) {

        throw new Error('Error al activar/desactivar cuestionario');

    }

};